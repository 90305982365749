import React from 'react'
import {Loader, Table, Pagination, Icon, Input, Button, Checkbox, Radio, Dropdown} from 'semantic-ui-react'
import {api} from "../axios";
import EsotiqInput from "../../components/EsotiqInput";
import {jsonCopy} from "../../helpers/lib";
import {AccessController, RouterController, TranslationController} from "../../controller/menager";
import SnackBar from "../../components/Snack";
import TableHeader from "../tableHeader";
import {pureCloseTableMenu} from "../../components/TableMenu";
import {closeDropdown} from "./menuButton";

class UITable extends React.Component {
    state = {
        data: this.props.data,
        appliedFilters: [],
        columns: Object.assign([], this.props.columns.filter(column => column).map((column, key) => ({...column, originalIndex: key}))),
        hiddenColumns: [],
        selectionState: [],
        tableLimit: null
    };

    init(limit, opts) {
        let options = this.props.dataFetch.options;

        if (this.state.currentPage) {
            options.currentPage = this.state.currentPage;
        }

        if (this.state.appliedFilters) {
            options = {...options, filter: [... this.state.appliedFilters, ...options.filter ? options.filter : []]}
        }

        if (this.state.tableLimit) {
            options.limit = this.state.tableLimit;
            options.pageSize = this.state.tableLimit;
        }

        if (limit) {
            options.limit = limit;
            options.pageSize = limit;
        }

        return api(
            this.props.dataFetch.useApi2 || false,
            this.props.dataFetch.forceUrl || undefined,
            this.props.dataFetch.useAuth || undefined,
            this.props.dataFetch.useService || undefined
        ).post(this.props.dataFetch.url, options)
            .then(r => {
                if (this.props.dataFetch.forceUrl) {
                    r.data.data = JSON.parse(r.data.data);
                }

                this.setData(r.data);

                this.props.provider.setState({
                    dataCount: r.data.total_count || r.data.pageSize
                });

                if (this.props.dataFetch.callback) {
                    this.props.dataFetch.callback(r.data, r.data.total_count || r.data.pageSize);
                }

                if (this.props.dataFetch.reverse) {
                    const reversed = r.data.data.reverse()

                    return reversed
                } else {
                    return r.data.data
                }
            })
    }

    setLimit(limit) {
        this.setState({
            data: null,
            tableLimit: limit,
            currentPage: 1
        }, () => {
            this.init(limit);
        })
    }

    getSelectedState() {
        const selectedKeys = [];
        const selectedRows = [];

        for (let i = 0; i < this.state.selectionState.length; i++) {
            if (this.state.selectionState[i] && this.state.selectionState[i].state.checked) {
                selectedKeys.push(i);
            }
        }

        selectedKeys.forEach(key => {
            if (this.state.data[key]) {
                selectedRows.push(this.state.data[key]);
            }
        })

        return selectedRows;
    }

    getSelectedCount() {
        return this.getSelectedState().length;
    }

    refreshCachedFilters() {
        this.state.appliedFilters = [];

        if (localStorage.getItem(this.props.forceStorageKey || `filters_${this.props.dataFetch.url}`)) {
            const filters = JSON.parse(localStorage.getItem(this.props.forceStorageKey || `filters_${this.props.dataFetch.url}`));

            this.state.appliedFilters = filters;
        }
    }

    componentDidMount() {
        RouterController.tableComponent = this;

        if (this.props.dataFetch) {
            if (localStorage.getItem(this.props.forceStorageKey || `filters_${this.props.dataFetch.url}`)) {
                const filters = JSON.parse(localStorage.getItem(this.props.forceStorageKey || `filters_${this.props.dataFetch.url}`));

                RouterController.setLocalState(this.props.forceStorageKey || `filters_${this.props.dataFetch.url}`, filters);

                this.state.appliedFilters = filters;
            }

            this.init();

            if (localStorage.getItem(`hidden_cols_${this.props.dataFetch.url}`)) {
                const hiddenColsIds = JSON.parse(localStorage.getItem(`hidden_cols_${this.props.dataFetch.url}`));

                this.state.hiddenColumns = [];

                hiddenColsIds.forEach(id => {
                    this.state.columns.forEach((c, index) => {
                        if (c.originalIndex === id) {
                            this.state.hiddenColumns.push({
                                column: c,
                                index
                            });
                        }
                    })
                })

                hiddenColsIds.forEach(id => {
                    this.state.columns = this.state.columns.filter((col, index) => col.originalIndex !== id);
                })

                this.forceUpdate();
            }
        }
    }

    setProviderDataTabel(data) {
        this.props.provider.setState({
            tableData: data,
            dataCount: 2
        })
    }

    setData(data) {
        this.setState({
            data: data.data,
            count: data.total_count || data.totalPages
        })

        this.setProviderDataTabel(data.data)
    }

    quickSearch(value) {
        this.applyFilter(this.state.appliedFilters, value);

        this.state.lastQuickSearch.push(value);
    }

    applyFilter(filters, quickSearch) {
        this.props.provider.setState({
            dataCount: null
        });

        let jsFilters = [];

        filters.forEach(filter => {
            if (filter.pattern) {
                jsFilters.push(filter)
            }
        })

        let formatedFilters = filters;

        this.setState({
            data: null
        });

        let result = {};
        let duplicates = [];

        formatedFilters.forEach((val) => {
            if (val.pattern) {
                return false
            }
            result[val.field] = val;
        });

        let finalFilters = [];

        Object.keys(result).forEach(field => {
            if (result[field].value !== undefined) {
                finalFilters.push(result[field])
            }
        });

        this.setState({
            appliedFilters: finalFilters
        });

        localStorage.setItem(this.props.forceStorageKey || `filters_${this.props.dataFetch.url}`, JSON.stringify(finalFilters));

        RouterController.setLocalState(this.props.forceStorageKey || `filters_${this.props.dataFetch.url}`, finalFilters);

        api(
            this.props.dataFetch.useApi2 || false,
            this.props.dataFetch.forceUrl || undefined,
            this.props.dataFetch.useAuth || undefined,
            this.props.dataFetch.useService || undefined
        ).post(this.props.dataFetch.url, {
            ...this.props.dataFetch.options,
            filter: finalFilters,
            quickSearch,
            limit: this.state.tableLimit || 25,
            pageSize: this.state.tableLimit || 25
        })
            .then(r => {
                if (this.props.dataFetch.forceUrl) {
                    r.data.data = JSON.parse(r.data.data);
                }

                this.setData(r.data);

                this.props.provider.setState({
                    dataCount: r.data.total_count
                });

                if (this.props.dataFetch.callback) {
                    this.props.dataFetch.callback(r.data, r.data.total_count);
                }

                if (this.props.dataFetch.reverse) {
                    const reversed = r.data.data.reverse();

                    return reversed
                } else {
                    return r.data.data
                }
            });

        this.props.provider.setState({
            appliedFilters: finalFilters,
            // noLimitCall: api().post(this.props.dataFetch.url, {filter: finalFilters})
            //     .then(r => {
            //         return r.data.data
            //     })
            noLimitCall: () => true
        })
    }

    jsFilter(filters) {
        this.setState({
            data: null
        })

        this.getFullData()
            .then(data => {
                let finalData = data

                filters.forEach(filter => {
                    let filterValue = filter.value

                    if (filterValue.includes("%")) {
                        filterValue = filterValue.replace("%", "").replace("%", "")
                    }

                    if (!filter.patternFunc) {
                        finalData = finalData.filter(item => item[filter.forceField].includes(filterValue))
                    } else {

                        finalData = finalData.filter(item => item["custom_attributes"].includes(filterValue))

                    }

                })

                this.setState({
                    data: {
                        items: finalData
                    }
                })
            })
    }

    getFullData() {
        //this.state.data.total_count
        return api(
            this.props.dataFetch.useApi2 || false,
            this.props.dataFetch.forceUrl || undefined,
            this.props.dataFetch.useAuth || undefined,
            this.props.dataFetch.useService || undefined
        ).post(this.props.dataFetch.url, {limit: this.state.data.total_count})
            .then(r => {
                if (this.props.dataFetch.forceUrl) {
                    r.data.data = JSON.parse(r.data.data);
                }

                if (this.props.dataFetch.reverse) {
                    const reversed = r.data.data.reverse()

                    return reversed
                } else {
                    return r.data.data
                }
            })
    }

    render() {
        const {data, count, columns} = this.state;
        const {provider, dataFetch, selectable, onRowSelect, className, ref, hideSelectAll, rowStyle, rowElementsHtmlId} = this.props;

        return (
            data ?
                <InitTable
                    ref={ref}
                    showPagination={this.props.showPagination}
                    className={className}
                    qs={this.state.lastQuickSearch}
                    onRowSelect={onRowSelect}
                    selectable={selectable}
                    rowElementsHtmlId={rowElementsHtmlId}
                    hideSelectAll={hideSelectAll}
                    data={data}
                    rowStyle={rowStyle}
                    count={count}
                    onPage={this.state.tableLimit || this.props.onPage || this.props.pageSize}
                    getSelectedCount={() => this.getSelectedCount()}
                    selectionState={() => ({
                        reset: () => this.state.selectionState = [],
                        set: (state) => this.state.selectionState.push(state),
                        isState: this.state.selectionState.length
                    })}
                    columns={columns.filter(e => !e.hidden)}
                    hideColumn={(column, index) => {
                        this.state.columns = this.state.columns.filter(col => col !== column);
                        this.state.hiddenColumns.push({column, index});

                        localStorage.removeItem(`hidden_cols_${this.props.dataFetch.url}`);
                        localStorage.setItem(`hidden_cols_${this.props.dataFetch.url}`, JSON.stringify(this.state.hiddenColumns.map(e => e.column.originalIndex)));

                        this.forceUpdate()
                    }}
                    showColumn={(column, index) => {
                        this.state.columns.splice(column.originalIndex, 0, column);
                        this.state.hiddenColumns = this.state.hiddenColumns.filter(col => col.column.originalIndex !== column.originalIndex);

                        this.state.columns = this.state.columns.sort((a, b) => a.originalIndex < b.originalIndex ? -1 : 0);

                        localStorage.removeItem(`hidden_cols_${this.props.dataFetch.url}`);
                        localStorage.setItem(`hidden_cols_${this.props.dataFetch.url}`, JSON.stringify(this.state.hiddenColumns.map(e => e.index)));


                        this.forceUpdate()
                    }}
                    hiddenColumns={this.state.hiddenColumns}
                    controller={this}
                    provider={provider}
                    dataFetch={dataFetch}
                /> :
                <div className={"table-loader"}>
                    <div className={"indicator-wrapper"}>
                        <Loader active/>
                    </div>
                </div>
        )
    }
}

class InitTable extends React.Component {
    state = {
        data: this.props.data,
        count: this.props.count,
        onPage: this.props.onPage || 25,
        currentPage: 1,
        isLoader: false,
        filters: {},
        filtersVisible: false,
        tableEditableRowState: {},
        allCheckboxesSelected: false,
        cb_id: this.randomString(),
        tableHeader: React.createRef()
    };

    setLimit(limit) {
        this.props.controller.setLimit(limit);
    }

    randomString() {
        const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const length = 32;

        let result = '';

        for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];

        return result;
    }

    selectCheckboxes = {}

    componentDidMount() {
        if (this.props.ref) {
            this.props.ref(this)
        }
    }

    getPagesCount() {
        return Math.ceil(this.state.count / this.state.onPage)
    }

    pageChange(e, {activePage}) {
        const selectionState = this.props.selectionState()

        for (let [key, checkbox] of Object.entries(this.selectCheckboxes)) {
            if (checkbox) {
                checkbox.state.checked = false;
            }
        }

        selectionState.reset();

        this.forceUpdate();

        this.props.controller.state.currentPage = activePage;

        window.scrollTo({top: 0, behavior: 'smooth'});

        this.setState({
            currentPage: activePage,
            isLoader: true
        });

        this.props.controller.init()
            .then(r => {
                this.setState({
                    data: r,
                    isLoader: false
                })
            })
    }

    quickSearch(value) {
        const controller = this.props.controller

        return controller.quickSearch(value)
    }

    updateSelectionState() {
        const selectionState = this.props.selectionState();

        setTimeout(() => {
            selectionState.reset();

            for (let [key, checkbox] of Object.entries(this.selectCheckboxes)) {
                selectionState.set(checkbox)
            }

            if (!this.state.blockUpdateCheckbox) {
                this.forceUpdate();
            }


            // this.tableHeader.setState({
            //     selectedCount: this.props.getSelectedCount()
            // })
        }, 100)
    }

    selectAll() {
        if (!this.state.allCheckboxesSelected) {
            for (let [key, checkbox] of Object.entries(this.selectCheckboxes)) {
                if ( this.selectCheckboxes[key].state.checked === true) {
                    document.getElementById(`${this.state.cb_id}_selectable_checkbox_${key}`).click()
                }
            }
        }

        this.state.blockUpdateCheckbox = true;

        setTimeout(() => {
            for (let [key, checkbox] of Object.entries(this.selectCheckboxes)) {
                document.getElementById(`${this.state.cb_id}_selectable_checkbox_${key}`).click();

                this.updateSelectionState();
            }
        }, 50);

        this.state.allCheckboxesSelected = true;

        setTimeout(() => {
            this.forceUpdate();

            this.state.blockUpdateCheckbox = false;
        }, 100)
    }

    renderTableValue(element, field) {
        let value = null

        if (field.includes(".")) {
            const nestings = field.split(".")

            nestings.forEach(nest => {
                if (!value) {
                    value = element[nest]
                } else {
                    value = value[nest]
                }
            })
        } else {
            value = element[field]
        }

        return value
    }

    removeFilter(filter) {
        const controller = this.props.controller;

        controller.state.appliedFilters = controller.state.appliedFilters.filter(e => e != filter);
        this.props.selectionState().reset();
        localStorage.removeItem(this.props.forceStorageKey || `filters_${this.props.dataFetch.url}`);
        localStorage.setItem(this.props.forceStorageKey || `filters_${this.props.dataFetch.url}`, JSON.stringify(controller.state.appliedFilters));

        RouterController.setLocalState(this.props.forceStorageKey || `filters_${this.props.dataFetch.url}`, controller.state.appliedFilters);

        return controller.applyFilter(controller.state.appliedFilters)
    }

    render() {
        const {data, isLoader, filtersVisible} = this.state;
        let {controller, columns, selectable, onRowSelect, className, hideSelectAll, hiddenColumns, rowStyle, rowElementsHtmlId} = this.props;
        return (
            <React.Fragment>
                    <div
                        className={`table-v2-active-filters ${className}`}
                        style={{
                            maxHeight: (hiddenColumns && hiddenColumns.length) ? "100px" : "0px",
                            padding: (hiddenColumns && hiddenColumns.length) ? "10px" : "0px 10px"
                        }}
                    >
                        <div style={{
                            fontSize: "0.8em",
                            color: "#9e9e9e"
                        }}>{TranslationController.translate("Ukryte kolumny")}:</div>

                        {(hiddenColumns && hiddenColumns.length) && hiddenColumns.map(({column, index}, key) => {
                            return (
                                <div
                                    onClick={() => {
                                        this.props.showColumn(column, index)
                                    }}
                                    className={"active-filter"}
                                    key={key}
                                    style={{
                                        marginRight: 15
                                    }}
                                >
                                    <Icon style={{fontSize: "0.8em", color: "#a1a1a1"}} name={"eye slash"}/>

                                    <div className={"active-filter-wrapper"}>
                                        <div className={"active-filter-label"}>{column.label}</div>
                                    </div>

                                    <div className={"filter-close-icon"} style={{
                                        right: -5
                                    }}>
                                        <Icon name={"close"}/>
                                    </div>
                                </div>
                            )
                        })}
                    </div>


                {controller.state.appliedFilters.length ?
                    <div
                        className={`table-v2-active-filters ${className}`}
                        style={{
                            maxHeight: controller.state.appliedFilters ? "100px" : "0px",
                            padding: controller.state.appliedFilters ? "10px" : "0px 10px"
                        }}
                    >
                        {controller.state.appliedFilters.map((filter, key) => {
                            if (!filter) {
                                return null
                            }

                            return (
                                <div onClick={() => this.removeFilter(filter)} className={"active-filter"} key={key}>
                                    <Icon style={{fontSize: "0.8em", color: "#a1a1a1"}} name={"filter"}/>
                                    <div className={"active-filter-wrapper"}>
                                        <div className={"active-filter-label"}>{TranslationController.translate(filter.label)}: </div>
                                        {Array.isArray(filter.value)
                                            ? <div className={"active-filter-values-wrapper"}>{filter.value.map((value, index) =>
                                                <div
                                                    key={index + key}
                                                    className={"active-filter-value"}
                                                >
                                                {filter.options ?
                                                    filter.options.find(option => option.value === value) ? filter.options.find(option => option.value === value).label : "" + " "
                                                    : value
                                                }
                                            </div>)}
                                          </div>
                                            : typeof filter.value != "object" ? <div
                                                    className={"active-filter-value"}>{filter.value === false ? TranslationController.translate("Nie") : filter.value ? ((filter.value.toString() == "1" || filter.value === true) ? TranslationController.translate("Tak") : (filter.value.toString() == "0" || filter.value === false) ? TranslationController.translate("Nie") : filter.value) : filter.value}</div>
                                                : <div
                                                    className={"active-filter-value"}>{TranslationController.translate("od")}: {filter.value.from} {TranslationController.translate("do")}: {filter.value.to}</div>
                                        }
                                    </div>

                                    <div className={"filter-close-icon"}>
                                        <Icon name={"close"}/>
                                    </div>
                                </div>
                            )
                        })}
                    </div> : null
                }

                {/*{qs && qs.length ?*/}
                    {/*<div>*/}
                        {/*<div>Ostatnio wyszukiwane:</div>*/}
                        {/*{*/}
                            {/*qs.map((qsItem, qsIndex) => {*/}
                                {/*console.log(qs, "RS")*/}
                                {/*return (*/}
                                    {/*<span key={qsIndex}>*/}
                                        {/*{qsItem}*/}
                                    {/*</span>*/}
                                {/*)*/}
                            {/*})*/}
                        {/*}*/}
                    {/*</div> : <React.Fragment/>*/}
                {/*}*/}

                <Table
                    className={"table-v2"}
                    celled
                >
                    {/*{isLoader &&*/}
                    {/*    <div className={"table-loader"}>*/}
                    {/*        <div className={"indicator-wrapper"}>*/}
                    {/*            <Loader active/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*}*/}

                    <TableHeader
                        ref={e => this.tableHeader = e}
                        {...this.props}
                        selectable={selectable}
                        hideSelectAll={hideSelectAll}
                        columns={columns}
                        filtersVisible={filtersVisible}
                        controller={controller}
                        onRowSelect={() => onRowSelect()}
                        selectAll={() => this.selectAll()}
                    />

                    <Table.Body style={{position: "relative"}}>
                        {data && Array.isArray(data) && data.map((element, key) => {
                            return (
                                <Table.Row
                                    key={key}
                                    style={rowStyle ? rowStyle(element, key) : null}
                                    id={rowElementsHtmlId ? rowElementsHtmlId[key] : null}
                                >
                                    {selectable &&
                                        <Table.Cell
                                            className={"cell-center cell-clickable"}
                                            onClick={() => {
                                                document.getElementById(`${this.state.cb_id}_selectable_checkbox_${key}`).click();
                                            }}
                                        >
                                            <Checkbox
                                                id={`${this.state.cb_id}_selectable_checkbox_${key}`}
                                                ref={e => this.selectCheckboxes[key] = e}
                                                onChange={(e) => {
                                                    this.updateSelectionState();

                                                    if (!onRowSelect) return;

                                                    const checkResult = onRowSelect(element, !this.selectCheckboxes[key].state.checked);

                                                    if (checkResult === "prevent") {
                                                        setTimeout(() => {
                                                            this.selectCheckboxes[key].state.checked = false;

                                                            this.forceUpdate();
                                                        }, 300)
                                                    }
                                                }}
                                            />
                                        </Table.Cell>
                                    }
                                    {columns.map(({field, render, selectable, center, onClick, bool, editable, preventDisablePaste, editableOptions, onEditableChange, onEditableConfirm, preventEditableSave, label, style}, columnIndex, self) => {
                                        if (render) {
                                            return (
                                                <React.Fragment key={columnIndex}>
                                                    <Table.Cell selectable={selectable}
                                                                style={style}
                                                                onClick={() => {
                                                                    if (onClick) {
                                                                        if (this.props.controller && this.props.controller.props.provider && this.props.controller.props.provider.props.match) {
                                                                            AccessController.onClickCheckAction(this.props.controller.props.provider.props.match, label)
                                                                                .then((accessResult) => {
                                                                                    if (accessResult) {
                                                                                        onClick(element)
                                                                                    } else {
                                                                                        SnackBar("Nie masz wystarczających uprawnień aby wykonać tą akcje.", "error")
                                                                                    }
                                                                                })
                                                                        } else {
                                                                            onClick(element)
                                                                        }
                                                                    }
                                                                }}
                                                                className={`${center ? "cell-center" : ""} ${onClick ? "cell-clickable" : ""}`}
                                                                key={columnIndex}>

                                                        {editable ?
                                                            <React.Fragment>
                                                                <div className={"ui-table-editable-box"}>
                                                                    {render(element, key)}

                                                                    <div
                                                                        className={"ui-table-editable-box-icon"}
                                                                        onClick={() => {
                                                                            if (this.state.tableEditableRowState[`${key}_${columnIndex}`]) {
                                                                                data[key] = this.state.tableEditableRowState[`${key}_${columnIndex}`]
                                                                            }

                                                                            this.state.tableEditableRowState[`${key}_${columnIndex}`] = this.state.tableEditableRowState[`${key}_${columnIndex}`] ? false : jsonCopy(element)

                                                                            this.forceUpdate()
                                                                        }}
                                                                    >
                                                                        <Icon name={"edit circle outline"}/>
                                                                    </div>
                                                                </div>

                                                                {this.state.tableEditableRowState[`${key}_${columnIndex}`] &&
                                                                <div>
                                                                    <EsotiqInput
                                                                        onChange={value => {
                                                                            if (onEditableChange) {
                                                                                onEditableChange(value, element)

                                                                                this.forceUpdate()
                                                                            }
                                                                        }}
                                                                        defValue={element[field]}
                                                                        disablePaste={!preventDisablePaste}
                                                                        {...editableOptions}
                                                                    />

                                                                    <div style={{
                                                                        display: "flex",
                                                                        marginTop: 5,
                                                                        position: "relative",
                                                                        justifyContent: "flex-end"
                                                                    }}>
                                                                        <Button
                                                                            onClick={() => {
                                                                                if (onEditableConfirm) {
                                                                                    onEditableConfirm(element, data)

                                                                                    if (preventEditableSave) {
                                                                                        data[key] = this.state.tableEditableRowState[`${key}_${columnIndex}`]
                                                                                    }

                                                                                    this.state.tableEditableRowState[`${key}_${columnIndex}`] = false

                                                                                    this.forceUpdate()
                                                                                }
                                                                            }}
                                                                        >
                                                                            {TranslationController.translate("Zapisz")}
                                                                        </Button>

                                                                        <Button
                                                                            negative={true}
                                                                            onClick={() => {
                                                                                data[key] = this.state.tableEditableRowState[`${key}_${columnIndex}`]

                                                                                this.state.tableEditableRowState[`${key}_${columnIndex}`] = false

                                                                                this.forceUpdate()
                                                                            }}
                                                                        >
                                                                            {TranslationController.translate("Anuluj")}
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                                }
                                                            </React.Fragment>
                                                        : render(element, key)
                                                        }
                                                    </Table.Cell>
                                                </React.Fragment>
                                            )
                                        }

                                        return (
                                            <React.Fragment
                                                key={columnIndex}
                                            >
                                                {selectable &&
                                                    <Table.Cell>
                                                        <span>check</span>
                                                    </Table.Cell>
                                                }
                                                <Table.Cell selectable={selectable}
                                                            style={style}
                                                            onClick={() => {
                                                                if (onClick) {
                                                                    if (this.props.controller && this.props.controller.props.provider && this.props.controller.props.provider.props.match) {
                                                                        AccessController.onClickCheckAction(this.props.controller.props.provider.props.match, label)
                                                                            .then((accessResult) => {
                                                                                if (accessResult) {
                                                                                    onClick(element)
                                                                                } else {
                                                                                    SnackBar("Nie masz wystarczających uprawnień aby wykonać tą akcje.", "error")
                                                                                }
                                                                            })
                                                                    }
                                                                }
                                                            }}
                                                            className={`${(center || bool) ? "cell-center" : ""} ${onClick ? "cell-clickable" : ""}`}
                                                            key={columnIndex}>
                                                    {bool ?
                                                        ((this.renderTableValue(element, field) == 0 || !this.renderTableValue(element, field))
                                                            ? <span style={{color: "#df3b4e"}}><Icon name={"times circle outline"}/></span>
                                                            :  <span style={{color: "#45b85d"}}><Icon name={"check circle outline"}/></span>)
                                                        : this.renderTableValue(element, field)}
                                                </Table.Cell>
                                            </React.Fragment>
                                        )
                                    })}
                                </Table.Row>
                            )
                        })}

                        {this.state.filtersVisible &&
                            <div onClick={() => this.setState({
                                filtersVisible: false
                            })} className={"table-onfilter-layer"}/>
                        }
                    </Table.Body>

                    <Table.Footer>
                    {(this.props.showPagination ?? true) &&
                        <Table.Row>
                            <Table.HeaderCell
                                colSpan={1}
                            >
                                <div className={"table-pagination-on-page"}>
                                    <div className={"table-pagination-on-page-label"}>
                                        {TranslationController.translate("Na stronie:")}
                                    </div>

                                    <Dropdown
                                        upward
                                        selection
                                        compact
                                        defaultValue={this.props.controller.state.tableLimit || 25}
                                        options={[
                                            { key: 25, text: '25', value: 25 },
                                            { key: 50, text: '50', value: 50 },
                                            { key: 100, text: '100', value: 100 },
                                            { key: 200, text: '200', value: 200 },
                                            { key: 300, text: '300', value: 300 },
                                            { key: 350, text: '350', value: 350 },
                                            { key: 400, text: '400', value: 400 },
                                            { key: 450, text: '450', value: 450 }
                                        ]}
                                        placeholder={TranslationController.translate("Na stronie:")}
                                        onChange={(e, v) => {
                                            this.setLimit(v.value);
                                        }}
                                    />

                                </div>
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan='7'>
                                {this.getPagesCount() > 1 &&
                                    <Pagination
                                        onPageChange={(e, page) => this.pageChange(e, page)}
                                        defaultActivePage={1}
                                        totalPages={this.getPagesCount()}/>
                                }
                            </Table.HeaderCell>
                        </Table.Row> }
                    </Table.Footer>
                </Table>

                <div onClick={() => {
                    const element = document.querySelector("#table-filter-layer");

                    if (element) {
                        document.querySelector("#table-filter-layer").classList.remove("table-on-hover")
                    }

                    this.tableHeader.setState({filtersVisible: false});

                    pureCloseTableMenu();
                    closeDropdown();
                }} id={"table-filter-layer"}/>

                {isLoader &&
                    <div style={{
                        position: "fixed",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#fff",
                        opacity: 0.6,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <Loader style={{
                            display: "block"
                        }} size='medium'/>
                    </div>
                }
            </React.Fragment>
        )
    }
}

export default UITable
