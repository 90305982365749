import {api} from "../../lib/axios";
import {jsonCopy} from "../../helpers/lib";

export function translateController() {
    this.dictioanryPL = require("./dictionary").dictionary;
    this.lang = null;
    this.dictionary = null;
    this.missingTranslations = {};
    this.dictionaryProducts = {};
}

translateController.prototype = {
    initDictionary: function (lang) {
        this.lang = lang

        return api().post("/api/dictionary/getMissingTranslation", {lang})
            .then(result => {
                for (let i = 0; i < result.data.translations.length; i++) {
                    this.missingTranslations[result.data.translations[i].word] = result.data.translations[i]
                }
            })
            .then(() => {
                if (lang !== "pl") {
                    return api().post("/api/dictionary/getDictionary", {lang})
                }
            })
            .then(dictionaryData => {
                if (dictionaryData && dictionaryData.data && dictionaryData.data.dictionary) {
                    this.dictionary = {};

                    for (let i = 0; i < dictionaryData.data.dictionary.length; i++) {
                        this.dictionary[dictionaryData.data.dictionary[i].word] = dictionaryData.data.dictionary[i].translate
                    }
                }
            })
    },
    translate: function (word) {
        if (this.lang === "pl") return word;

        let translated = null;

        if (this.dictionary && this.dictionary[word]) {
            translated = this.dictionary[word];
        }

        if (translated) {
            return translated
        } else {
            this.sendToTranslate(word);

            return `${word}`
        }
    },
    sendToTranslate: function (word) {
        if (this.lang !== "pl" && word && typeof word === "string") {
            if (!this.missingTranslations[word]) {
                api().post("/api/dictionary/setMissingTranslation", {lang: this.lang, word})
            }
        }
    },
    setTranslateProducts: function (products) {
        products.forEach(product => {
            if (product.countries) {
                for (let [key, value] of Object.entries(product.countries)) {
                    const lang = key.split("_")[1];

                    if (lang && lang !== "pl") {
                        if (!this.dictionaryProducts[lang]) {
                            this.dictionaryProducts[lang] = {};
                        }

                        this.dictionaryProducts[lang][product.name] = value.name;
                    }
                }
            }
        })
    },
    translateProduct: function (name) {
        if (this.lang !== "pl") {
            let lang = "";

            switch (this.lang) {
                case "en":
                    lang = "gb";
                    break;
                default:
                    lang = this.lang;

                    break;
            }

            if (this.dictionaryProducts[lang]) {
                if (this.dictionaryProducts[lang][name]) {
                    console.log(this.dictionaryProducts[lang][name], "??")

                    return this.dictionaryProducts[lang][name];
                } else {
                    return name;
                }
            } else {
                return name;
            }
        }

        return name;
    }
}
