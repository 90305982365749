import React from "react"
import ActionPanel from "../../components/ActionPanel";
import Container from "../../components/Container";
import Breadcrumb from "../../components/Breadcrumb";
import Button from "../../components/Button";
import {Segment, Table, Modal, Menu, Dimmer, Loader, Checkbox} from "semantic-ui-react";
import UITable from "../../lib/table-v2";
import {OrderStatusesHelper} from "../../helpers/orderStatuses";
import {formatDate} from "../../helpers/dateHelper";
import Icon from '@material-ui/core/Icon';
import {TranslationController} from "../../controller/menager";
import {getCurrencyString} from "../../lib/currency";
import MenuButton from "../../lib/table-v2/menuButton";
import {api} from "../../lib/axios";
import {saveToFile} from "../../controller/exportController";
import SnackBar from "../../components/Snack";
import Confirm from "../../components/Confirm";
import {DATA_OBJECT} from "../../controller/dataController";
import TableMenu, {closeTableMenu, preventCloseTableMenu} from "../../components/TableMenu";
import EsotiqInput from "../../components/EsotiqInput";
// import {LoaderContext} from "../../store/LoaderContext";
// import {multiMailer} from "../../helpers/multiMailer";
// import RequestLoader from "../../components/RequestLoader/Loader";

export default class OnlineComplaintListWarehouse extends React.Component {
    constructor(props) {
        super(props);

        this.downloadLink = {}

        this.state = {
            generatedPdf: {},
            changeStatus: false,
            changeStatusValue: null,
            tableExportCols: []
        }

        this.exportFields = [
            {
                field: "sku",
                label: "SKU",
            },
            {
                field: "shop",
                label: "Shop",
            },
            {
                field: "name",
                label: "Nazwa"
            },
            {
                field: "country",
                label: "Kraj",
            },
            {
                field: "base_price",
                label: "Cena bazowa",
            },
            {
                field: "price",
                label: "Cena aktualna",
            },
            {
                field: "reference_price",
                label: "Cena referencyjna",
            },
            {
                field: "discount_percentage",
                label: "Przecena",
            },
            {
                field: "season",
                label: "Sezon",
            },
        ];

        this.uniExportFields = [
            {
                field: "revertSource",
                label: "Źródło zgłoszenia",
            },
            {
                field: "status",
                label: "Status",
            },
            {
                field: "complaintNo",
                label: "Nr zgłoszenia"
            },
            {
                field: "revertType",
                label: "Rodzaj zgłoszenia",
            },
            {
                field: "created",
                label: "Data zgłoszeia",
                backendFormat: "DATE"
            },
            {
                field: "p_sku",
                label: "Towary w zgłoszeniu",
            },
            {
                field: "p_revertQty",
                label: "Ilość towarów",
            },
            {
                field: "p_price",
                label: "Cena towaru",
            },
            {
                field: "p_name",
                label: "Nazwa towaru",
            },
            {
                field: "p_ean",
                label: "Kod ean",
            },
            {
                field: "p_revertReason",
                label: "Powód zwrotu",
            },
            {
                field: "isSalon",
                label: "Czy salonowe",
            }
        ];
    }

    uniExport() {
        this.setState({
            loading: true
        });

        preventCloseTableMenu(false);
        closeTableMenu();

        return api().post("/api/complaints/get", {
            isExport: {
                columns: this.state.tableExportCols,
                fields: this.uniExportFields
            },
            limit: 35000,
            currentPage: 1,
            filter: this.orderTable.state.appliedFilters
        })
            .then(r => {
                this.setState({
                    loading: false,
                    tableExportCols: []
                });

                if (r.data.error) {
                    return SnackBar(r.data.message, "error")
                }

                return saveToFile(r.data);
            });
    }

    render() {
        return (
            <React.Fragment>
                <ActionPanel
                    actions={[]}
                />


                <Container>
                    <Breadcrumb
                        crumbs={[
                            {
                                label: `${TranslationController.translate("Zgłoszenia reklamacji i zwrotów towarów")} ${this.state.dataCount ? `(${this.state.dataCount})` : ""}`
                            }
                        ]}
                    />

                    <div style={{height: 10}}></div>

                    <Menu className={"order-menu"} attached='top'>
                        <TableMenu icon={"wrench"}>
                            <TableMenu.Menu>
                                <TableMenu.Header>Akcje zbiorcze</TableMenu.Header>

                                <TableMenu.Item onClick={() => this.setState({tableSelectable: !this.state.tableSelectable, tableSelectableState: []})}>Zaznaczanie</TableMenu.Item>
                                <TableMenu.Item
                                    onClick={()=>{
                                        this.setState({changeStatus: true})
                                    }}
                                >Zmień status</TableMenu.Item>

                                <TableMenu.Item
                                    onClick={()=>{
                                        Confirm("Czy zaktualizować wszystkie wybrane reklamacje?", "", ()=>{
                                            this.setState({
                                                loading: true
                                            });
                                            const timestamp = new Date().getTime();
                                            return api().post("/api/complaints/multiUpdate", {
                                                complaints: this.orderTable.getSelectedState().map((complaint) => (
                                                    {
                                                        _id: complaint._id,
                                                        isXml: true,
                                                        history: (complaint.history && complaint.history.length) ? [ ... complaint.history, {
                                                            value: `Zmieniono status XML na TAK`,
                                                            timestamp: timestamp,
                                                            subValue: DATA_OBJECT.user.login
                                                        }] : [{
                                                            value:`Zmieniono status XML na TAK`,
                                                            timestamp: timestamp,
                                                            subValue: DATA_OBJECT.user.login
                                                        }]
                                                    }
                                                )),
                                                userId: DATA_OBJECT.user.id

                                            }).then(
                                                r=> {
                                                    SnackBar("Zaktualizowano reklamacje")
                                                    this.setState({
                                                        tableSelectableState: [],
                                                        loading: false,
                                                    });
                                                    this.orderTable.setState({
                                                        data: null
                                                    })
                                                    this.orderTable.init()
                                                }
                                            ).catch(err => SnackBar(`${err.response.data}`, "error"))
                                        })
                                    }}
                                >Wygenerowano XML > Tak</TableMenu.Item>
                                <TableMenu.Item
                                    onClick={()=>{
                                        if(this.orderTable.getSelectedState().length === 0) return SnackBar('Nie wybrano reklamacji', "error");
                                        Confirm("Czy zaktualizować wszystkie wybrane reklamacje?", "", ()=>{
                                            this.setState({
                                                loading: true
                                            });
                                            const timestamp = new Date().getTime();
                                            return api().post("/api/complaints/multiUpdate", {
                                                complaints: this.orderTable.getSelectedState().map((complaint) => (
                                                    {
                                                        _id: complaint._id,
                                                        isXml: false,
                                                        history: (complaint.history && complaint.history.length) ? [ ... complaint.history, {
                                                            value: `Zmieniono status XML na NIE`,
                                                            timestamp: timestamp,
                                                            subValue: DATA_OBJECT.user.login
                                                        }] : [{
                                                            value:`Zmieniono status XML na NIE`,
                                                            timestamp: timestamp,
                                                            subValue: DATA_OBJECT.user.login
                                                        }]
                                                    }
                                                )),
                                                userId: DATA_OBJECT.user.id

                                            }).then(
                                                r=> {
                                                    SnackBar("Zaktualizowano reklamacje")
                                                    this.setState({
                                                        tableSelectableState: [],
                                                        loading: false,
                                                    });
                                                    this.orderTable.setState({
                                                        data: null
                                                    })
                                                    this.orderTable.init()
                                                }
                                            ).catch(err => SnackBar(`${err.response.data}`, "error"))
                                        })
                                    }}
                                >Wygenerowano XML > Nie</TableMenu.Item>
                                <TableMenu.Item
                                    onClick={()=>{
                                        if(this.orderTable.getSelectedState().length === 0) return SnackBar('Nie wybrano reklamacji', "error");

                                        let canContinue = true;

                                        this.orderTable.getSelectedState().forEach(complaint => {
                                            this.orderTable.getSelectedState().forEach(complaint2 => {
                                                if (complaint.revertType !== complaint2.revertType) canContinue = false;
                                            })
                                        })

                                        if (!canContinue) {
                                            SnackBar("Zaznaczono różne typy zgłoszeń operacja nie moze zostać wykonana. Zaznacz zgłosznie tego samego typu.", "error");
                                            return;
                                        }

                                        Confirm("Czy wystawić koretkę dla wybranych?", "", ()=>{
                                            this.setState({
                                                loading: true
                                            });
                                            return api().post("/api/complaints/createCorrections", {
                                                complaints: this.orderTable.getSelectedState().map((complaint) => complaint),
                                                userId: DATA_OBJECT.user.id

                                            }).then(
                                                r=> {
                                                    if (r.data.error) {
                                                        SnackBar(r.data.message, "error");
                                                    }

                                                    this.setState({
                                                        tableSelectableState: [],
                                                        loading: false,
                                                    });
                                                    this.orderTable.setState({
                                                        data: null
                                                    })
                                                    this.orderTable.init()
                                                }
                                            ).catch(err => SnackBar(`${err.response.data}`, "error"))
                                        })
                                    }}
                                >Wystaw korekty do zgłoszeń</TableMenu.Item>
                                <TableMenu.Item>
                                    <span className='text'>Raport uniwersalny</span>

                                    <TableMenu.Menu>
                                        <div className={"uni-report-container"}>
                                            <div className={"uni-report-header"}>Wybierz kolumny do raportu</div>

                                            <div className={"uni-report-content-box"}>
                                                <div className={"uni-report-column"}>
                                                    <ul>
                                                        {this.uniExportFields.map((fieldValues, key) => {
                                                            return (
                                                                <li key={key}>
                                                                    <Checkbox key={key} onClick={(e) => {
                                                                        const field = fieldValues.field

                                                                        return !this.state.tableExportCols.includes(field) ? this.state.tableExportCols.push(field) : this.state.tableExportCols = this.state.tableExportCols.filter(col => col != field)
                                                                    }} label={fieldValues.label}/>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className={"uni-report-actions"}>
                                                <Button
                                                    color='blue'
                                                    content='Pobierz'
                                                    icon='download'
                                                    label={{ basic: true, color: 'blue', pointing: 'left', content: '.csv' }}
                                                    onClick={() => this.uniExport()}
                                                />
                                            </div>
                                        </div>
                                    </TableMenu.Menu>
                                </TableMenu.Item>
                            </TableMenu.Menu>
                        </TableMenu>
                        <Menu.Menu position='left'>
                            <MenuButton
                                icon={"download"}
                                onClick={() => {
                                    api().post("/api/complaints/warehouseExport", {
                                        filter: this.orderTable.state.appliedFilters,
                                        isExport: {
                                            columns:["sku",
                                                // "shop", "name", "country","base_price", "price","reference_price","discount_percentage","season"
                                            ],
                                            fields: this.exportFields
                                        },
                                        limit: 35000,
                                        currentPage: 1,
                                    })
                                        .then((r) => {
                                            if (r.data) {
                                                saveToFile(r.data, null, null, null, "warehouse_export.csv");
                                            }

                                            SnackBar("Rozpoczęto proces eksportu.")
                                        })
                                }}
                                label={"Eksportuj"}
                            />
                            <MenuButton
                                icon={"download"}
                                onClick={() => {
                                        return Confirm("Czy na pewno chcesz wygenerować plik?", "", () => {
                                            return api().post("api/complaints/generateReturnsXml", {
                                                filters: this.orderTable.state.appliedFilters,
                                                user: DATA_OBJECT.user.login
                                            }).then((res) => {
                                                const xmltext = res.data.xml;

                                                const filename = `${DATA_OBJECT["user"]["login"]}_${formatDate(
                                                    new Date().getTime()
                                                )
                                                    .dateTimeSystem
                                                    .replaceAll("-","_")
                                                    .replaceAll(" ","_")
                                                    .replaceAll(":","_")
                                                }.xml`;

                                                const pom = document.createElement('a');
                                                const bb = new Blob([xmltext], {type: 'text/plain'});

                                                pom.setAttribute('href', window.URL.createObjectURL(bb));
                                                pom.setAttribute('download', filename);

                                                pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
                                                pom.draggable = true;
                                                pom.classList.add('dragout');

                                                pom.click();
                                            }).then(r => {
                                                return api().post("/api/complaints/multiUpdate", {
                                                    complaints: this.orderTable.state.data.map(complaint => ({
                                                        _id: complaint._id,
                                                        isXml: true
                                                    })),
                                                    userId: DATA_OBJECT.user.id
                                                }).then(r => {
                                                    this.orderTable.setState({
                                                        data: null
                                                    })
                                                    this.orderTable.init()
                                                })
                                            })
                                        })
                                    }}
                                label={"Eksportuj XML"}
                            />
                        </Menu.Menu>
                    </Menu>

                    <Segment className={"order-table-segment"} attached='bottom'>
                        <UITable
                            dataFetch={{
                                url: "/api/complaints/get",
                                options: {limit: 25, currentPage: 1},
                                callback: async (complaints) => {
                                    await (() => new Promise(resolve => {
                                        api(true).post("/api/orders/bindComments",  {
                                            ordersIds: complaints.data.map(e => e.orderId)
                                        })
                                            .then(r => {
                                                if (r && r.data && r.data.result) {
                                                    for (let [orderId, comments] of Object.entries(r.data.result)) {
                                                        comments.forEach(comm => {
                                                            complaints.data.map(e => {
                                                                if (parseInt(comm.orderId) === parseInt(e.orderId)) {
                                                                    e.commentsCount = (e.commentsCount || 0) + 1
                                                                }
                                                            })
                                                        })
                                                    }
                                                }

                                                resolve();
                                            })
                                    }))();

                                    if (this.orderTable) {
                                        this.orderTable.setState({
                                            data: complaints.data
                                        });
                                    }
                                }
                            }}
                            selectable={this.state.tableSelectable}
                            ref={table => this.orderTable = table}
                            provider={this}
                            columns={[
                                {
                                    label: TranslationController.translate("Numer zgłoszenia"),
                                    field: "complaintNo",
                                    onClick: row => {
                                        window.open("#/complaint-list-warehouse/" + row._id, '_blank')
                                    },
                                    filters: [
                                        {label: TranslationController.translate("Numer zgłoszenia"), condition: "like", type: "text"}
                                    ]
                                },
                                {
                                    label: "Typ",
                                    field: "revertType",
                                    render: row => row.revertType === "complaint" ? TranslationController.translate("Reklamacja") : row.revertType === "revert" ? TranslationController.translate("Zwrot") : TranslationController.translate("Zwrot + reklamacja"),
                                    filters: [
                                        {label: "Status", condition: "in", type: "checkbox", forceField: "revertType", options: [
                                                {
                                                    value: "revert",
                                                    label: TranslationController.translate("Zwrot")
                                                }, {
                                                    value: "complaint",
                                                    label: TranslationController.translate("Reklamacja")
                                                }, {
                                                    value: "revert_complaint",
                                                    label: TranslationController.translate("Zwrot + reklamacja")
                                                }
                                            ]
                                        },
                                    ]
                                },
                                {
                                    label: "Zwrot XML",
                                    field: "isXml",
                                    bool: true,
                                    filters: [
                                        {label: "Zwrot XML", condition: "existsWithBoolean", type: "toggle", matchValue: {"1": true, "0": false}}
                                    ]
                                },
                                {
                                    label: "Status",
                                    field: "status",
                                    render: row => <div className={"es-order-satus-display"}><span style={{
                                        background: new OrderStatusesHelper().getColor(row.status),
                                        color: new OrderStatusesHelper().getFontColor(row.status)
                                    }}
                                        className={"value"}>{new OrderStatusesHelper().getLabel(row.status)}</span>
                                    </div>,
                                    filters: [
                                        {label: "Status", condition: "in", type: "checkbox", forceField: "status", options: [
                                                {
                                                    value: "complaintNew",
                                                    label: new OrderStatusesHelper().statuses.complaintNew.label
                                                }, {
                                                    value: "complaintProgress",
                                                    label: new OrderStatusesHelper().statuses.complaintProgress.label
                                                }, {
                                                    value: "complaintAccepted",
                                                    label: new OrderStatusesHelper().statuses.complaintAccepted.label
                                                }, {
                                                    value: "complaintRejected",
                                                    label: new OrderStatusesHelper().statuses.complaintRejected.label
                                                }
                                            ]
                                        },
                                    ]
                                },
                                {
                                    label: "Data",
                                    field: "createdAt",
                                    render: row => formatDate(row.createdAt).dateTimeSystem,
                                    center: true,
                                    filters: [
                                        {label: "Sortuj", condition: "in", type: "radio", forceField: "sort_createdAt", options: [
                                                {
                                                    value: "asc",
                                                    label: "Rosnąco"
                                                }, {
                                                    value: "desc",
                                                    label: "Malejąco"
                                                }
                                            ]
                                        },
                                        {label: "Data", condition: "between", type: "date", forceField: "createdAt"},
                                    ]
                                },
                                {
                                    label: "Zwrócone produkty",
                                    field: "status",
                                    render: row => {
                                        return (
                                            <div style={{
                                                marginTop: 10
                                            }}>
                                                <Table
                                                    basic='very'
                                                >
                                                    {/*<Table.Header>*/}
                                                    {/*    <Table.Row className={"ui-table-tr-background"}>*/}
                                                    {/*        <Table.HeaderCell>{TranslationController.translate("SKU")}</Table.HeaderCell>*/}
                                                    {/*        <Table.HeaderCell>{TranslationController.translate("Ilość")}</Table.HeaderCell>*/}
                                                    {/*    </Table.Row>*/}
                                                    {/*</Table.Header>*/}

                                                    <Table.Body>
                                                        {(row.revertData || []).map((item, index) => {

                                                            if (item.revertTok99) {
                                                                row.revertToK99 = true
                                                            }

                                                            return (
                                                                <Table.Row key={index}
                                                                           className={"ui-table-tr-background"}>
                                                                    <Table.Cell style={{width: 200}}>
                                                                        {item.sku}
                                                                        {item.revertTok99 &&
                                                                        <div style={{
                                                                            fontSize: "0.85em",
                                                                            color: "#ff4a2d",
                                                                            fontWeight: 600
                                                                        }}>{TranslationController.translate("Towar odeślij na mag. K99")}</div>
                                                                        }
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        {item.revertQty}
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            )
                                                        })}
                                                    </Table.Body>
                                                </Table>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    label: "Nr zamówienia",
                                    field: "orderId",
                                    filters: [
                                        {label: "Numer zamówienia", condition: "in", type: "in"},
                                        {label: "Wystawiono fakturę", forceField: 'hasInvoice', condition: "eq", type: "radio", options: [
                                                {
                                                    value: "FV",
                                                    label: "Faktura Frontier"
                                                },
                                                {
                                                    value: "FA",
                                                    label: "Faktura K2"
                                                },
                                                {
                                                    value: "NO",
                                                    label: "Brak faktury"
                                                }
                                            ]
                                        }
                                    ],
                                    render: row => <a target={"_blank"} href={`#/order/${row.orderId}`}>{row.orderId}</a>
                                },
                                {
                                    label: "Źródło / Typ",
                                    field: "revertSource",
                                    center: true,
                                    filters: [
                                        {label: "Źródło / Typ", condition: "like", type: "text"},
                                        {label: "Salon", forceField: "isSalon", condition: "eq", type: "toggle"},
                                        {label: "Outlet", forceField: "isOutlet", condition: "eq", type: "toggle"},
                                        {label: "K99", forceField: "isK99", condition: "eq", type: "toggle"}
                                    ],
                                    render: row => {
                                        if (!row.orderId) {
                                            row.orderId = 0;
                                        }

                                        let isParlourOrder = false;

                                        if (row.orderId.toString().charAt(0) === "2") {
                                            isParlourOrder = true;
                                        }

                                        if (row.orderData) {
                                            if (row.orderData.data) {
                                                if (row.orderData.data.additionalOptions && row.orderData.data.additionalOptions.forceWarehouse && row.orderData.data.additionalOptions.forceWarehouse === "k99") {
                                                    isParlourOrder = false;
                                                }

                                                if (row.orderData.data.additionalOptions && row.orderData.data.additionalOptions.forceWarehouse && row.orderData.data.additionalOptions.forceWarehouse === "x99") {
                                                    isParlourOrder = true;
                                                }

                                                if (row.orderData.data.additionalOptions && row.orderData.data.additionalOptions.forceWarehouse && row.orderData.data.additionalOptions.forceWarehouse === "salonowe") {
                                                    isParlourOrder = true;
                                                }
                                            } else {
                                                if (row.orderData.additionalOptions && row.orderData.additionalOptions.forceWarehouse && row.orderData.additionalOptions.forceWarehouse === "k99") {
                                                    isParlourOrder = false;
                                                }

                                                if (row.orderData.additionalOptions && row.orderData.additionalOptions.forceWarehouse && row.orderData.additionalOptions.forceWarehouse === "x99") {
                                                    isParlourOrder = true;
                                                }

                                                if (row.orderData.additionalOptions && row.orderData.additionalOptions.forceWarehouse && row.orderData.additionalOptions.forceWarehouse === "salonowe") {
                                                    isParlourOrder = true;
                                                }
                                            }
                                        }

                                        return (
                                            <div className={"es-order-shippingType"}>
                                                {row.revertSource}
                                                {isParlourOrder ? <div className={"salon-label"}>salon</div> : <div className={"shipp-label"}></div>}
                                            </div>
                                        )
                                    }
                                },
                                {
                                    label: "Komentarze",
                                    width: 1,
                                    center: true,
                                    onClick: row => {
                                        window.open("#/complaint-list-warehouse/" + row._id, '_blank')
                                    },
                                    render: row => <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <Icon style={{
                                            width: 20,
                                            marginTop: 3,
                                            marginRight: 5
                                        }}>comment</Icon> <span>{row.commentsCount ? `(${row.commentsCount})` : "---"}</span>
                                    </div>
                                },
                                {
                                    label: "Podgląd",
                                    width: 1,
                                    center: true,
                                    onClick: row => {
                                        window.open("#/complaint-list-warehouse/" + row._id, '_self')
                                    },
                                    render: row => <div>
                                        <Icon>search</Icon>
                                    </div>
                                },
                                {
                                    label: "Usuń",
                                    width: 1,
                                    center: true,
                                    render: row =>  <div style={{
                                        cursor: "pointer"
                                    }} onClick={() => {
                                        Confirm(`Czy na pewno zamierzasz usunąć zgłoszenie ${row.complaintNo} ?`, "", () => {
                                            api().post("/api/complaints/remove", {
                                                data: {
                                                    id: row._id
                                                }
                                            })
                                                .then(() => {
                                                    SnackBar("Usunięto")
                                                    this.orderTable.setState({
                                                        data: null
                                                    })

                                                    this.orderTable.init()
                                                })
                                        })
                                    }}>
                                        <i className='trash link icon' />
                                    </div>
                                }
                            ]}
                        />
                    </Segment>

                    {this.state.imageModal &&
                    <Modal dimmer={"inverted"} size={"medium"} open={this.state.imageModal}
                           onClose={() => this.setState({imageModal: false})}>
                        <Modal.Header></Modal.Header>
                        <Modal.Content>
                            <div>
                                <img style={{
                                    maxWidth: 500,
                                    margin: "0 auto",
                                    display: "block"
                                }} src={this.state.imageModal} alt=""/>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({imageModal: false})} negative>Zamknij</Button>
                        </Modal.Actions>
                    </Modal>
                    }
                    {this.state.changeStatus &&
                        <Modal dimmer={"inverted"} size={"mini"} open={this.state.changeStatus} onClose={() => {
                            this.setState({
                                changeStatus: false,
                                changeStatusValue: null,
                            });
                        }}>
                            <Modal.Header>{TranslationController.translate("Zmień status reklamacji")}</Modal.Header>
                            <Modal.Content>
                                <EsotiqInput
                                    label={"Status"}
                                    options={new OrderStatusesHelper().complaintStatuses.map((element, key) => (
                                        {
                                            key, value: element.id, text: TranslationController.translate(element.label)
                                        }
                                    ))}
                                    onChange={(v) => {
                                        this.setState({changeStatusValue: v});
                                    }}
                                />

                            </Modal.Content>
                            <Modal.Actions>
                                <Button onClick={() => {

                                    this.setState({
                                        changeStatus: false,
                                        changeStatusValue: null,
                                    });
                                }} negative>Anuluj</Button>
                                <Button
                                    positive
                                    icon='checkmark'
                                    labelPosition='right'
                                    content='Zapisz'
                                    onClick={() => {

                                        Confirm("Czy na pewno chcesz zmienić status reklamacji?", null, () => {
                                            this.setState({
                                                loading: true
                                            });
                                            const timestamp = new Date().getTime();
                                            api().post("/api/complaints/multiUpdate", {
                                                complaints: this.orderTable.getSelectedState().map(complaint => ({
                                                    _id: complaint._id,
                                                    status: this.state.changeStatusValue,
                                                    history: (complaint.history && complaint.history.length) ? [ ... complaint.history, {
                                                        value: `Zmieniono status zgłoszenia z ${new OrderStatusesHelper().getLabel(complaint.status)} na ${new OrderStatusesHelper().getLabel(this.state.changeStatusValue)}`,
                                                        timestamp: timestamp,
                                                        subValue: DATA_OBJECT.user.login
                                                }] : [{
                                                        value:`Zmieniono status zgłoszenia z ${new OrderStatusesHelper().getLabel(complaint.status)} na ${new OrderStatusesHelper().getLabel(this.state.changeStatusValue)}`,
                                                        timestamp: timestamp,
                                                        subValue: DATA_OBJECT.user.login
                                                }]
                                                })),
                                                userId: DATA_OBJECT.user.id})
                                                .then(r => {

                                                    // this.context.dispatch({type: 'loading', payload: {
                                                    //         count: 0,
                                                    //         completed: 0,
                                                    //         actionAllowed: true,
                                                    //         data: this.orderTable.getSelectedState(),
                                                    //         status: this.state.changeStatusValue
                                                    //     }})
                                                    //
                                                    // console.log(this.context.loading)

                                                    this.setState({changeStatus: false, changeStatusValue: null});

                                                    this.setState({
                                                        loading: false,
                                                    });

                                                    if (r.data.error) {
                                                        SnackBar(r.data.message, "error");
                                                    } else {
                                                        SnackBar("Zaktualizowano status reklamacji");

                                                        this.orderTable.setState({
                                                            data: null,
                                                        });

                                                        this.orderTable.init()
                                                    }
                                                })
                                        })
                                    }}
                                />
                            </Modal.Actions>
                        </Modal>
                    }
                    {this.state.loading &&
                        <Dimmer active inverted style={{
                            position: "fixed",
                            zIndex: 99999
                        }}>
                            <Loader size='medium'>{TranslationController.translate("Proszę czekać... trwa synchronizacja zamówień.")}</Loader>
                        </Dimmer>
                    }

                </Container>
                {/*{this.context.loading && <RequestLoader data={this.context.loading} action={multiMailer}/>}*/}
            </React.Fragment>
        );
    }
}

// OnlineComplaintListWarehouse.contextType = LoaderContext;
