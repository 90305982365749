import React from "react";
import {Dimmer, Icon, Input, Loader} from "semantic-ui-react";
import {TranslationController} from "../../../controller/menager";
import SnackBar from "../../../components/Snack";
import {api} from "../../../lib/axios";

export default class CartItemsScanner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dimmer: true
        }
    }

    setCartItemsToDefaultScannerState() {
        this.props.items.map(item => {
            item.scanned = 0;
        })
    }

    bindNecessaryDetailsToCartItems(serverItems) {
        if (!serverItems) return;
        this.props.items.map(item => {
            serverItems.forEach(product => {
                if (item.sku.includes(product.model)) {
                    item.name = product.name;

                    if (product.images && product.images[0]) {
                        item.img = product.images[0].file
                    }
                }
            })
        })
    }

    bindEansToCartItems(eans) {
        const eanMap = {};
        eans.forEach(ean => eanMap[ean.sku] = ean);

        this.props.items.map(item => {
            if (!eanMap[item.sku]) {
                return SnackBar(`Nie znaleziono kodu EAN dla jednego z produktów (${item.sku}). Zgłoś do operatora.`, "error")
            }

            item.ean = eanMap[item.sku].ean;
            item.scanned = 0;
        })
    }

    componentDidMount() {
        this.setCartItemsToDefaultScannerState();

        const items = this.props.items;
        const products = [];

        items.map(item => products.push(item.sku))

        api().post("/api/products/getProductsBySku", {index: products})
            .then(r => {
                this.bindNecessaryDetailsToCartItems(r.data.data);
                return api().post("/api/products/getEan", {sku: products, limit: 9999, currentPage: 1})
            })
            .then(r => {
                this.bindEansToCartItems(r.data.data);
                this.setState({
                    dimmer: false
                }, () => {
                    this.onScanner();
                })
            })
    }

    scan(ean) {
        let dataItem = null;
        let itemKey = 0;
        let input = document.getElementById("scan-input");

        input.value = '';
        input.focus();

        this.props.items.forEach((item, key) => {
            if (item.ean === ean) {
                dataItem = item;
                itemKey = key;
            }
        })

        if (!dataItem) {
            return SnackBar("Nie znaleziono towaru. Niepoprawny kod EAN.", "error")
        }

        dataItem.scanned = dataItem.scanned + 1
        const isOverScanned = this.checkComplete();
        const htmlElementIds = this.props.rowElementsHtmlId;

        if (htmlElementIds) {
            this.scrollToItem((itemKey + 1).toString());
        }

        if ((dataItem.scanned == dataItem.qty) || (dataItem.scanned + 1 == dataItem.qty)) {
            this.props.reload();
            return SnackBar("Zeskanowano poprawnie wszystkie towary z indeksu.")
        } else {
            this.props.reload();

            if (isOverScanned) {
                return SnackBar("Zeskanowałeś już wystarczającą ilość towaru.", "warning");
            }

            return SnackBar("Zeskanowano poprawnie.")
        }
    }

    checkComplete() {
        let isSuccess = true;
        let isOverScanned = false;

        this.props.items.map(item => {
            if (item.scanned < item.qty) {
                isSuccess = false;
            }

            if (item.scanned > item.qty) {
                isSuccess = false;
                isOverScanned = true;
            }
        })

        if (isSuccess) {
            this.onScanComplete();
        }

        if (isOverScanned) {
            this.props.onNotComplete();
        }

        return isOverScanned;
    }

    onScanComplete() {
        this.props.onComplete();
    }

    scrollToItem(elementId) {
        var element = document.getElementById(elementId);

        if (element) {
            var headerOffset = 100;
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }

    onScanner() {
        let isEvent = false;
        let input = document.getElementById("scan-input");

        input.addEventListener("focus", () => {
            if (!isEvent) {
                isEvent = true;

                input.addEventListener("keypress", (e) => {
                    setTimeout(() => {
                        if (e.keyCode == 13) {
                            this.scan(input.value);
                        }
                    }, 500)
                })
            }
        });

        document.addEventListener("keypress", (e) => {
            if (e.target.tagName !== "INPUT") {
                input.focus();
            }
        });
    }

    render() {
        return (
            <div style={{
                display: "block",
                position: "relative"
            }}>
                <div className={"cart-title"}>
                    <Icon name={"barcode"}/>
                    {TranslationController.translate("Zeskanuj towary")}
                </div>

                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    maxWidth: 800
                }}>
                    <Input
                        action={{
                            color: 'teal',
                            labelPosition: 'right',
                            icon: 'tag',
                            content: TranslationController.translate("Zatwierdź"),
                            onClick: () => this.scan(this.state.givenEan)
                        }}
                        placeholder={TranslationController.translate("Wprowadź EAN")}
                        onChange={(e, v) => {this.setState({
                            givenEan: v.value
                        })}}
                        id={"scan-input"}
                    />
                </div>

                {this.state.dimmer &&
                    <Dimmer active inverted style={{
                        position: "absolute",
                        zIndex: 99999,
                        maxWidth: 800
                    }}>
                        <Loader size='medium'>{TranslationController.translate("Proszę czekać, trwa pobieranie EAN...")}</Loader>
                    </Dimmer>
                }
            </div>
        )
    }
}
