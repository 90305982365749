import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink, Font, Image } from '@react-pdf/renderer';
import {
    Step,
    Icon,
    Radio,
    Input,
    Table,
    Checkbox,
    Confirm as SemanticConfirm,
    Dimmer,
    Loader,
    Icon as SemanticIcon
} from "semantic-ui-react";
import {formatDate} from "../../helpers/dateHelper";
import SnackBar from "../../components/Snack";
import OrderServiceEANScan from "./orderServiceEanScan";
import {api, STATIC_URL} from "../../lib/axios";
import { useBarcode } from '@createnextapp/react-barcode';
import Button from "../../components/Button";
import {TranslationController} from "../../controller/menager";
import {DATA_OBJECT} from "../../controller/dataController";
import Confirm from "../../components/Confirm";
import WarehouseOrderServiceEANScan from "./orderServiceEanScan";
import {copyToClipboard} from "../../lib/globals";

const sourceLight = require("../../resources/fonts/OpenSans-Light.ttf")
const sourceRegular = require("../../resources/fonts/OpenSans-Regular.ttf")

Font.register({ family: 'OpenSansLight', src: sourceLight });
Font.register({ family: 'OpenSans', src: sourceRegular });

function BarCode(props) {
    const { inputRef } = useBarcode({
        value: props.id,
        options: {
            height: 25
        }
    });

    return <img id={props.id} ref={inputRef}/>
}

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        padding: "40px 20px",
        fontFamily: 'OpenSans'
    },
    section: {
        margin: 10,
        padding: 10,
    },
    title: {
        color: "#545454",
        fontSize: 24,
        fontWeight: 700,
    },
    subinfo: {
        fontSize: 10,
        textAlign: "right",
        marginTop: 5,
        fontFamily: 'OpenSansLight'
    },
    contactBox: {
        marginTop: 10,
        borderBottomWidth: 1,
        borderColor: "#000",
        paddingBottom: 5
    },
    subinfoLeft: {
        fontSize: 10,
        marginTop: 5,
        fontFamily: 'OpenSansLight'
    },
    tableBox: {
        margin: "10px 0"
    },
    tableHeader: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%"
    },
    tableHeaderCol: {
        padding: 3,
        borderWidth: 1,
        borderBottomWidth: 0,
        flex: 1,
    },
    tableHeaderColNoWidth: {
        padding: 3,
        borderWidth: 1,
    },
    tableHeaderColText: {
        fontSize: 10,
        textAlign: "center",
    },
    tableBody: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%"
    },
    tableBodyCol: {
        padding: 3,
        borderWidth: 1,
        flex: 1,
    },
    tableBodyColText: {
        fontSize: 10,
        textAlign: "center",
    },
    tableBodyColNoWidth: {
        padding: 3,
        borderWidth: 1,
    },
    tableBoxEnd: {
        width: "50%",
        marginTop: 5
    },
    tableBodyColCustom: {
        padding: 3,
        borderWidth: 1,
        flex: 1,
        height: 50
    },
    finalText: {
        textAlign: "center",
        marginTop: 10,
        fontSize: 9,
        fontFamily: 'OpenSansLight'
    },
    finalBox: {
        marginTop: 0
    }
});

// Create Document Component
class OrderPackingPDF extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            productPages: []
        }
    }

    componentDidMount() {
        const chunks = [];

        const chunkSize = 18;
        for (let i = 0; i < this.props.data.sending.length; i += chunkSize) {
            const chunk = this.props.data.sending.slice(i, i + chunkSize);

            chunks.push(chunk);
        }

        this.setState({
            productPages: chunks
        })
    }

    render() {
        const {barcode} = this.props;

        let isExternalOrder = false;
        let isDEOrder = false;

        if (this.props.data && this.props.data.order) {
            isExternalOrder = (this.props.data.order.id ?? this.props.data.orderId).toString().charAt(0) === "8";
            isDEOrder = (this.props.data.order.id ?? this.props.data.orderId).toString().charAt(0) === "4";
        }

        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={{
                        paddingBottom: 3,
                        borderBottomWidth: 3,
                        borderColor: "#9d9d9d",
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}>
                        <View style={{
                            width: "35%",
                        }}>
                            <Image src={barcode}/>
                        </View>
                        <View>
                            <Text style={styles.title}>{TranslationController.translate("Specyfikacja towarowa")}</Text>
                        </View>
                    </View>

                    <View>
                        <Text style={styles.subinfo}>{formatDate().dateNow}</Text>
                        <Text style={styles.subinfo}>{TranslationController.translate("Salon")}: {this.props.data && this.props.data.parlour}</Text>
                    </View>

                    <View style={styles.contactBox}>
                        <Text style={styles.subinfoLeft}>{TranslationController.translate("Biuro Obsługi Klienta Pn-Pt 7:30-15:30")}</Text>
                        <Text style={styles.subinfoLeft}>{TranslationController.translate("kontakt@esotiq.com tel. 799 352 880")}</Text>
                    </View>

                    <View style={styles.tableBox}>
                        <View style={styles.tableHeader}>
                            <View style={styles.tableHeaderCol}>
                                <Text style={styles.tableHeaderColText}>{TranslationController.translate("Numer zamówienia")}</Text>
                            </View>
                            <View style={{...styles.tableHeaderCol, ...{borderRightWidth: 0, borderLeftWidth: 0}}}>
                                <Text style={styles.tableHeaderColText}>{TranslationController.translate("Data zamówienia")}</Text>
                            </View>
                            <View style={styles.tableHeaderCol}>
                                <Text style={styles.tableHeaderColText}>{TranslationController.translate("Forma wysyłki")}</Text>
                            </View>
                        </View>
                        <View style={styles.tableBody}>
                            <View style={styles.tableBodyCol}>
                                <Text style={styles.tableBodyColText}>{this.props.data && (this.props.data.order.id ?? this.props.data.orderId)}</Text>
                            </View>
                            <View style={{...styles.tableBodyCol, ...{borderRightWidth: 0, borderLeftWidth: 0}}}>
                                <Text style={styles.tableBodyColText}>---</Text>
                            </View>
                            <View style={styles.tableBodyCol}>
                                <Text style={styles.tableBodyColText}>---</Text>
                            </View>
                        </View>
                    </View>

                    <View>
                        <Text style={{
                            fontSize: 10,
                            fontWeight: 600,
                        }}>
                            {isExternalOrder ? TranslationController.translate("Przesunięcie do E99") : isDEOrder ? TranslationController.translate("Przesunięcie do D99") : TranslationController.translate("Przesunięcie do S99")}
                        </Text>
                    </View>

                    <View style={styles.tableBox}>
                        <View style={styles.tableHeader}>
                            <View style={{...styles.tableHeaderColNoWidth, ...{width: "13%", borderBottomWidth: 0}}}>
                                <Text style={styles.tableHeaderColText}>{TranslationController.translate("Spakowano")}</Text>
                            </View>
                            <View style={{...styles.tableHeaderColNoWidth, ...{width: "27%", borderRightWidth: 0, borderLeftWidth: 0, borderBottomWidth: 0}}}>
                                <Text style={styles.tableHeaderColText}>{TranslationController.translate("SKU")}</Text>
                            </View>
                            <View style={{...styles.tableHeaderColNoWidth, ...{width: "10%", borderRightWidth: 0, borderBottomWidth: 0}}}>
                                <Text style={styles.tableHeaderColText}>{TranslationController.translate("Ilość")}</Text>
                            </View>
                            <View style={{...styles.tableHeaderColNoWidth, ...{width: "50%", borderBottomWidth: 0}}}>
                                <Text style={styles.tableHeaderColText}>{TranslationController.translate("Nazwa")}</Text>
                            </View>
                        </View>
                        {this.state.productPages && this.state.productPages[0] && this.state.productPages[0].map((e, k) => {
                            return (
                                <View key={k} style={styles.tableBody}>
                                    <View style={{...styles.tableBodyColNoWidth, ...{width: "13%"}}}>
                                    </View>
                                    <View style={{...styles.tableBodyColNoWidth, ...{width: "27%",  borderRightWidth: 0, borderLeftWidth: 0}}}>
                                        <Text style={styles.tableBodyColText}>{e.sku}</Text>
                                    </View>
                                    <View style={{...styles.tableBodyColNoWidth, ...{width: "10%", borderRightWidth: 0}}}>
                                        <Text style={styles.tableBodyColText}>{e.qty}</Text>
                                    </View>
                                    <View style={{...styles.tableBodyColNoWidth, ...{width: "50%"}}}>
                                        <Text style={styles.tableBodyColText}>{DATA_OBJECT["user"] && (DATA_OBJECT["user"]["country"] === "pl" ? e.name : (DATA_OBJECT["user"]["country"] === "de" && e.deName) ? e.deName : e.sku)}</Text>
                                    </View>
                                </View>
                            )
                        })}
                    </View>

                    {this.state.productPages && (this.state.productPages.length === 1) &&
                        <View>
                            <View style={styles.tableBoxEnd}>
                                <View style={styles.tableHeader}>
                                    <View style={styles.tableHeaderCol}>
                                        <Text style={styles.tableHeaderColText}>{TranslationController.translate("Spakował (podpis)")}</Text>
                                    </View>
                                </View>
                                <View style={styles.tableBody}>
                                    <View style={styles.tableBodyColCustom}>
                                    </View>
                                </View>
                            </View>

                            <View style={styles.finalBox}>
                                <Text style={styles.finalText}>{TranslationController.translate("Dziękujemy za zakupy")}</Text>
                                <Text style={styles.finalText}>{TranslationController.translate("Proszę o zgłoszenie niezgodności w dostawie niezwłocznie adres mailowy biura obsługi klienta kontakt@esotiq.com podając numer zamówienia w tytule wiadomości.")}</Text>
                            </View>
                        </View>
                    }
                </Page>

                {this.state.productPages && this.state.productPages.length > 1 &&
                    this.state.productPages.map((page, index, self) => {
                        if (index === 0) return null;

                        return (
                            <Page size="A4" style={styles.page}>
                                {page.map((e, k) => (
                                    <View key={k} style={styles.tableBody}>
                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "13%"}}}>
                                        </View>
                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "27%",  borderRightWidth: 0, borderLeftWidth: 0}}}>
                                            <Text style={styles.tableBodyColText}>{e.sku}</Text>
                                        </View>
                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "10%", borderRightWidth: 0}}}>
                                            <Text style={styles.tableBodyColText}>{e.qty}</Text>
                                        </View>
                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "50%"}}}>
                                            <Text style={styles.tableBodyColText}>{(DATA_OBJECT["user"] && DATA_OBJECT["user"]["country"] === "pl") ? e.name : e.sku}</Text>
                                        </View>
                                    </View>
                                ))}

                                {index === self.length - 1 &&
                                    <View>
                                        <View style={styles.tableBoxEnd}>
                                            <View style={styles.tableHeader}>
                                                <View style={styles.tableHeaderCol}>
                                                    <Text style={styles.tableHeaderColText}>{TranslationController.translate("Spakował (podpis)")}</Text>
                                                </View>
                                            </View>
                                            <View style={styles.tableBody}>
                                                <View style={styles.tableBodyColCustom}>
                                                </View>
                                            </View>
                                        </View>

                                        <View style={styles.finalBox}>
                                            <Text style={styles.finalText}>{TranslationController.translate("Dziękujemy za zakupy")}</Text>
                                            <Text style={styles.finalText}>{TranslationController.translate("Proszę o zgłoszenie niezgodności w dostawie niezwłocznie adres mailowy biura obsługi klienta kontakt@esotiq.com podając numer zamówienia w tytule wiadomości.")}</Text>
                                        </View>
                                    </View>
                                }
                            </Page>
                        )
                    })
                }
            </Document>
        );
    }
}

export default class WarehouseOrderPackingPDFDocument extends React.Component {
    constructor(props) {
        super(props);

        this.downloadLink = React.createRef()

        this.state = {
            completedStep: this.props.parlour === "E99" ? 3 : 0,
            rejected: {},
            rejectedItems: [],
            accepted: null,
            orderScanningController: null,
            stockMap: {}
        }
    }

    componentDidMount() {
        if (this.props.previouslyScanned) {
            this.setState({
                accepted: this.props.data.sending
            })
        }

        this.getProductStock();
    }

    getProductStock() {
        api().post("/api/states/getStock", {
            sku: this.props.data.sending.map(e => e.sku),
            warehouse: this.props.parlour
        })
            .then((r) => {
                if (r.data.data.errorCode > 0) {
                    return SnackBar(r.data.data.errorMessage, "error")
                }

                const stockMap = {};

                r.data.data.quantities.map(item => {
                    if (stockMap[item.sku]) stockMap[item.sku] = 0;
                    stockMap[item.sku] = item.stan;
                })

                this.setState({
                    stockMap
                })
            })
    }

    reject() {
        const rejectedItems = []

        Object.keys(this.state.rejected)
            .forEach(sku => {
                rejectedItems.push(this.state.rejected[sku])
            })

        this.state.rejectedItems = rejectedItems;
        this.forceUpdate();
    }

    completeStep(step) {
        if (step < this.state.completedStep) {
            return;
        }

        if (step === "1") {
            api().post("/api/orderService/update", {
                id: this.props.data._id,
                orderId: this.props.orderId,
                parlour: this.props.parlour,
                update: {
                    hasPdf: true
                }
            })
        }

        if (step === "4") {
            api().post("/api/orderService/update", {
                id: this.props.data._id,
                orderId: this.props.orderId,
                parlour: this.props.parlour,
                loki: this.props.data.$loki,
                update: {
                    numberMM: this.state.givenMM
                }
            })
        }

        if (step === "2") {
            if (this.state.completedStep < 1) {
                SnackBar("Najpierw pobierz list pakowy i sprawdź stan towarowy.", "error")
                return false
            }
        }

        this.setState({
            completedStep: parseInt(step)
        })
    }

    resetPreviouslyScanned() {
        this.props.reset();

        api().post("/api/orderService/resetScan", {
            id: this.props.data._id,
            orderId: this.props.orderId,
            parlour: this.props.parlour,
        })
            .then(r => {
                this.props.recallData()
            })
    }

    acceptOrder() {
        const parlourData = this.props.data

        parlourData.sending = parlourData.sending.map(item => ({...item, ...{status: "accepted"}}));
        parlourData.status = "accepted";
        parlourData.acceptedTime = formatDate().dateNowSystem;

        api().post("/api/orderService/accept", {
            orderId: this.props.orderId,
            parlour: this.props.parlour,
            orderService: parlourData
        })
            .then(r => {
                if (r.data && r.data.error) {
                    this.setState({
                        dimmer:false
                    })

                    return SnackBar(r.data.message, "error")
                }
            })
    }

    rejectOrder() {
        const rejectedData = this.state.rejected;
        const parlourData = this.props.data;

        Object.keys(rejectedData)
            .forEach(rejectedSku => {
                parlourData.sending.map(item => {
                    if (item.sku === rejectedSku) {
                        item.status = "rejected"
                        item.statusHistory.push({
                            status: "rejected",
                        })
                        item.rejectedCount = parseInt(rejectedData[rejectedSku].qty);

                        if (item.rejectedCount > parseInt(rejectedData[rejectedSku].qty)) {
                            item.rejectedCount = parseInt(rejectedData[rejectedSku].qty)
                        }
                    } else {
                        if (item.status !== "rejected") {
                            item.statusHistory.push({
                                status: "canceled",
                            });

                            item.status = "canceled"
                        }
                    }
                })
            });

        parlourData.status = "rejected"

        parlourData.rejectedTime = formatDate().dateNowSystem

        api().post("/api/orderService/reject", {
            orderId: this.props.orderId,
            parlour: this.props.parlour,
            orderService: parlourData,
            userId: DATA_OBJECT["user"]["login"]
        })
            .then(r => {
                if (r.data && r.data.error) {
                    this.setState({
                        dimmer:false
                    })

                    return SnackBar(r.data.message, "error")
                }

                if (this.props.orderId.toString().charAt(0) === "8") {
                    window.open("#/warehouse/shipping/" + this.props.parlour, "_self")
                } else {
                    window.open("#/warehouse/shipping/" + this.props.parlour, "_self")
                }
            })
    }

    fillRejectInputsWithValidStocks() {
        this.props.data.sending.forEach(sendItem => {
            if (this.state.stockMap[sendItem.sku] < sendItem.qty) {
                const htmlInput = document.getElementById("input-reject-" + sendItem.sku);

                if (htmlInput) {
                    if (!this.state.rejected) this.state.rejected = {};
                    if (!this.state.rejected[sendItem.sku]) this.state.rejected[sendItem.sku] = sendItem.qty - this.state.stockMap[sendItem.sku];

                    htmlInput.value = sendItem.qty - this.state.stockMap[sendItem.sku];

                    const rejectedItems = []

                    Object.keys(this.state.rejected)
                        .forEach(sku => {
                            rejectedItems.push(this.state.rejected[sku])
                        })

                    this.state.rejectedItems = rejectedItems;
                }
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <div style={{
                    width: 800,
                    display: "block",
                    margin: "0 auto",
                    marginBottom: 10
                }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                        <Button
                            onClick={() => {
                                window.open("#/warehouse/shipping", "_self")
                            }}
                        >
                            {TranslationController.translate("Powrót")}
                        </Button>
                    </div>
                </div>

                <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <div style={{
                        display: "none",
                        position: "absolute"
                    }}>
                        <BarCode id={this.props.orderId}/>
                    </div>

                    <div style={{
                        margin: "0 auto",
                        display: "flex",
                        justifyContent: "center",
                    }}>
                        <Step.Group style={{
                            width: 800
                        }} vertical>
                            <Step active={this.props.previouslyScanned ? false : this.state.completedStep === 0} completed={this.props.previouslyScanned ? true : this.state.completedStep >= 1}>
                                <Icon name='cloud download' />
                                <Step.Content>
                                    <Step.Title>{TranslationController.translate("List pakowy")}</Step.Title>
                                    <Step.Description>{TranslationController.translate("Pobierz dokument w PDF")}</Step.Description>

                                    <div style={{marginTop: 5}}></div>

                                    {this.props.parlour !== "E99" &&
                                        <React.Fragment>
                                            {this.state.generatedPdf ?
                                                <PDFDownloadLink
                                                    ref={e => this.downloadLink = e}
                                                    document={
                                                        <OrderPackingPDF
                                                            data={this.props.data}
                                                            fileName={`łist_pakowy_${(this.props.data.order.id ?? this.props.data.orderId)}`}
                                                            barcode={document.getElementById(this.props.orderId).src}
                                                        />
                                                    }
                                                >
                                                    {({ blob, url, loading, error }) => {
                                                        if (error) {
                                                            console.log(error, "Error while pdf generate")
                                                        }

                                                        if (!loading && url) {
                                                            this.state.generatedPdf = false;

                                                            if (this.props.pdfOnly) {
                                                                setTimeout(() => {
                                                                    window.open("#/warehouse/shipping/" + this.props.parlour, "_self")
                                                                }, 500)
                                                            } else {
                                                                this.completeStep("1")
                                                            }

                                                            window.open(url, '_blank')
                                                        }

                                                        return (loading ? <Button
                                                            color='blue'
                                                            loading={true}
                                                            label={{ basic: true, color: 'blue', pointing: 'left', content: '.pdf' }}
                                                        /> : <Button
                                                            color='blue'
                                                            content='Pobierz'
                                                            icon='download'
                                                            label={{ basic: true, color: 'blue', pointing: 'left', content: '.pdf' }}
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                if (this.props.pdfOnly) {
                                                                    setTimeout(() => {
                                                                        window.open("#/warehouse/shipping/" + this.props.parlour, "_self")
                                                                    }, 500)
                                                                } else {
                                                                    this.completeStep("1")
                                                                }
                                                                window.open(url, '_blank')
                                                            }}
                                                        />)
                                                    }}
                                                </PDFDownloadLink> : <Button
                                                    color='blue'
                                                    content='Generuj'
                                                    icon='download'
                                                    label={{ basic: true, color: 'blue', pointing: 'left', content: '.pdf' }}
                                                    onClick={() => this.setState({
                                                        generatedPdf: true
                                                    })}
                                                />}
                                        </React.Fragment>
                                    }
                                </Step.Content>
                            </Step>

                            {!this.props.pdfOnly &&
                            <React.Fragment>
                                <Step
                                    disabled={this.props.previouslyScanned ? false : this.state.completedStep < 1}
                                    active={this.state.completedStep === 1}
                                    completed={this.props.previouslyScanned ? true : this.state.completedStep >= 2}
                                >
                                    <Icon name='calendar check outline' />
                                    <Step.Content>
                                        <Step.Title>{TranslationController.translate("Sprawdź stan towarowy")}</Step.Title>
                                        <Step.Description>{TranslationController.translate("Dokładnie upewnij się, że faktycznie posiadasz towary na stanie")}</Step.Description>

                                        <div style={{marginTop: 5}}>
                                            <div style={{
                                                marginBottom: 5,
                                                marginTop: 5
                                            }}>
                                                <Radio
                                                    name={"stateCheckin"}
                                                    value={"x"}
                                                    disabled={this.props.previouslyScanned ? false : this.state.completedStep < 1}
                                                    checked={this.state.imitCheckbox ? this.state.imitCheckbox === 'this' : this.props.previouslyScanned}
                                                    onChange={() => {
                                                        this.setState({
                                                            imitCheckbox: "this",
                                                            rejectedTable: false,
                                                            rejected: {},
                                                            rejectedItems: []
                                                        });
                                                        this.completeStep("2");

                                                        document.getElementById("scan-input").focus()
                                                    }}
                                                    label={TranslationController.translate("Tak, posiadam całe zamówienie na stanie")}
                                                />
                                            </div>
                                            <div>
                                                <Radio
                                                    name={"stateCheckin"}
                                                    value={"y"}
                                                    disabled={this.props.previouslyScanned ? false : this.state.completedStep < 1}
                                                    checked={this.state.imitCheckbox === 'that'}
                                                    onChange={() => {
                                                        this.setState({
                                                            rejectedTable: true,
                                                            imitCheckbox: "that",
                                                            completedStep: 1,
                                                            givenMM: null
                                                        }, () => this.fillRejectInputsWithValidStocks());

                                                        if (this.state.orderScanningController) {
                                                            this.state.orderScanningController.reset();
                                                        }

                                                        if (this.props.previouslyScanned) {
                                                            this.resetPreviouslyScanned()
                                                        }
                                                    }}
                                                    label={TranslationController.translate("Nie, brakuje niektórych pozycji")}
                                                />
                                            </div>

                                            {this.state.rejectedTable &&
                                            <div className={"rejectedTable"} style={{marginTop: 10}}>
                                                <Table celled>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell></Table.HeaderCell>
                                                            <Table.HeaderCell>{TranslationController.translate("SKU")}</Table.HeaderCell>
                                                            <Table.HeaderCell>{TranslationController.translate("Nazwa")}</Table.HeaderCell>
                                                            <Table.HeaderCell>{TranslationController.translate("Ilość")}</Table.HeaderCell>
                                                            <Table.HeaderCell>{TranslationController.translate("Stan mag.")}</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>

                                                    <Table.Body>
                                                        {this.props.data.sending.map((item, key) => {
                                                            return (
                                                                <Table.Row style={{
                                                                    background: this.state.stockMap[item.sku] < item.qty ? "#ffd3d3" : ""
                                                                }}>
                                                                    <Table.Cell>
                                                                        <Checkbox
                                                                            disabled={!this.state.rejected[item.sku]}
                                                                            onClick={() => {
                                                                                if (!this.state.rejected[item.sku]) {
                                                                                    return SnackBar("Wprowadź ilość brakującego towaru.", "error")
                                                                                }

                                                                                this.reject()
                                                                            }}
                                                                            onChange={() => {
                                                                            }}
                                                                        />
                                                                    </Table.Cell>
                                                                    <Table.Cell>{item.sku}</Table.Cell>
                                                                    <Table.Cell>{TranslationController.translateProduct(item.name)}</Table.Cell>
                                                                    <Table.Cell>
                                                                        <Input
                                                                            id={"input-reject-" + item.sku}
                                                                            label={{ basic: true, content: `z ${item.qty}` }}
                                                                            labelPosition='right'
                                                                            placeholder='Szt.'
                                                                            onChange={(e, v) => {
                                                                                const {rejected} = this.state

                                                                                if (!v.value.length) {
                                                                                    delete rejected[item.sku]

                                                                                    return this.setState({
                                                                                        rejected
                                                                                    })
                                                                                }

                                                                                if (v.value > item.qty) {
                                                                                    delete rejected[item.sku]

                                                                                    this.setState({
                                                                                        rejected
                                                                                    })
                                                                                    return SnackBar("Wprowadzona ilość brakującego towaru jest wyższa niż faktyczny stan zamówienia.", "error")
                                                                                } else {
                                                                                    rejected[item.sku] = {
                                                                                        qty: v.value
                                                                                    }

                                                                                    this.setState({
                                                                                        rejected
                                                                                    })
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Table.Cell>
                                                                    <Table.Cell>{this.state.stockMap[item.sku] ?? "0"}</Table.Cell>
                                                                </Table.Row>
                                                            )
                                                        })}
                                                    </Table.Body>
                                                </Table>

                                                <Button
                                                    onClick={() => {
                                                        this.setState({
                                                            confirm: true
                                                        })
                                                    }}
                                                    color={"blue"}
                                                    icon
                                                    labelPosition='right'
                                                    disabled={!this.state.rejectedItems.length}
                                                >
                                                        {TranslationController.translate("Zgłoś brak wybranych towarów i odrzuć zamówienie")}
                                                    <Icon name='checkmark' />
                                                </Button>

                                                <SemanticConfirm
                                                    dimmer={"inverted"}
                                                    open={this.state.confirm}
                                                    content={'Czy na pewno chcesz odrzucić zamówienie?'}
                                                    onCancel={() => this.setState({confirm: false})}
                                                    onConfirm={() => {
                                                        this.setState({confirm: false, dimmer: true});
                                                        this.rejectOrder();
                                                    }}
                                                />
                                            </div>
                                            }
                                        </div>
                                    </Step.Content>
                                </Step>

                                <Step
                                    disabled={this.props.previouslyScanned ? false : this.state.completedStep < 2}
                                    active={this.props.previouslyScanned ? true : this.state.completedStep === 2}
                                    completed={this.state.completedStep >= 3}
                                >
                                    <Icon name='barcode' />
                                    <Step.Content style={{width: "92%"}}>
                                        <Step.Title>{TranslationController.translate("Zeskanuj kody kreskowe")}</Step.Title>
                                        <WarehouseOrderServiceEANScan
                                            parlour={this.props.parlour}
                                            orderId={this.props.orderId}
                                            stockMap={this.state.stockMap}
                                            orderServiceId={this.props.data._id}
                                            disabled={this.props.previouslyScanned ? false : this.state.completedStep < 2}
                                            sending={this.props.data.sending}
                                            setOrderScanningController={(controller) => this.state.orderScanningController = controller}
                                            onSuccess={() => {
                                                this.setState({
                                                    accepted: this.props.data.sending
                                                })

                                                this.completeStep("3")
                                            }}
                                            onRejected={() => {
                                                this.setState({
                                                    rejectedTable: true,
                                                    imitCheckbox: "that",
                                                    completedStep: 1,
                                                    givenMM: null
                                                })
                                            }}
                                            reset={() => this.resetPreviouslyScanned()}
                                            previouslyScanned={this.props.previouslyScanned}
                                        />
                                    </Step.Content>
                                </Step>

                                <Step style={{
                                    width: "100%"
                                }} disabled={this.state.completedStep < 3} active={this.state.completedStep === 3} completed={this.state.completedStep >= 4}>
                                    <Icon name='calendar check' />
                                    <Step.Content style={{
                                        width: "90%"
                                    }}>
                                        <Step.Title>{TranslationController.translate("Dokument magazynowy")}</Step.Title>

                                        <div style={{
                                            marginTop: 10,
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "100%"
                                        }}>
                                            {
                                                (this.props.data.numberMM) ?
                                                    <div style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        justifyContent: "space-between"
                                                    }}>
                                                        <div>
                                                            <div
                                                                style={{cursor: "pointer"}}
                                                                onClick={() => {
                                                                    copyToClipboard(this.props.data.numberMM);
                                                                }}
                                                            >
                                                                <div style={{
                                                                    fontSize: "0.9em"
                                                                }}>{TranslationController.translate("Dokument")}:</div>

                                                                <div style={{
                                                                    fontSize: "1em",
                                                                    fontWeight: 600
                                                                }}>
                                                                    <SemanticIcon style={{cursor: "pointer"}} className={"hover-lb"} name={"copy"}/>
                                                                    {this.props.data.numberMM}
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <div style={{
                                                                    marginTop: 20,
                                                                    fontSize: "0.9em"
                                                                }}>{TranslationController.translate("Data wystawienia")}:</div>
                                                                <div style={{
                                                                    fontSize: "1em",
                                                                    fontWeight: 600
                                                                }}>{formatDate(this.props.data.mmDocument.createdAt).dateNowSystem}</div>
                                                            </div>
                                                        </div>
                                                        <div style={{marginTop: 10}}>
                                                            {this.state.completedStep < 3 &&
                                                                <div className={"eanScan-disabled"}></div>
                                                            }

                                                            <div style={{
                                                                display: "flex"
                                                            }}>
                                                                <Button onClick={() => {
                                                                    window.open(this.props.data.mmDocument.pdf)
                                                                }}
                                                                        primary={this.state.completedStep >= 3}
                                                                >
                                                                    {TranslationController.translate("Pobierz dokument MM")}
                                                                </Button>

                                                                <Button onClick={() => {
                                                                    return Confirm(
                                                                        "Czy na pewno chcesz wygenerować nowy list przewozowy?",
                                                                        "",
                                                                        () => {
                                                                            this.setState({
                                                                                dimmer: true
                                                                            })

                                                                            api().post("/api/orderService/generatePickup", {
                                                                                orderId: this.props.orderId,
                                                                                parlour: this.props.parlour,
                                                                                id: this.props.rowId,
                                                                            }).then((r) => {
                                                                                this.setState({
                                                                                    dimmer: false
                                                                                })

                                                                                if (r.data.validation && r.data.validation.ValidationInfo && r.data.validation.ValidationInfo.length) {
                                                                                    r.data.validation.ValidationInfo.forEach((e) => {
                                                                                        SnackBar(e.Info, "error")
                                                                                    })
                                                                                } else {
                                                                                    if (process.env.REACT_APP_RUN_GLS_PICKUP === "true") {
                                                                                        const format = ["K99", "E99"].includes(DATA_OBJECT.selected_parlour) ? 'smallPdf' : 'a4';
                                                                                        api().get(`/api/orders/delivery/getLabel/${r.data.data}/gls?format=${format}&parlour=${DATA_OBJECT.selected_parlour}`)
                                                                                        .then((r) => {
                                                                                            if(r.data.error) {
                                                                                                return SnackBar(r.data.error, "error")
                                                                                            }
                                                                                            const arrayBuffer = Uint8Array.from(r.data.label.data).buffer;

                                                                                            const blob = new Blob([arrayBuffer], {type: 'application/pdf'});
                                                                                            const blobUrl = URL.createObjectURL(blob);
                                                                                            window.open(blobUrl, "_blank")
                                                                                        })
                                                                                    } else {
                                                                                        window.open(`${STATIC_URL}${r.data.file}`)
                                                                                    }

                                                                                    this.setState({
                                                                                        refresh: new Date().getTime(),
                                                                                        dimmer: false
                                                                                    })
                                                                                }
                                                                            })
                                                                        })
                                                                }}
                                                                        primary={this.state.completedStep >= 3}
                                                                >
                                                                    {TranslationController.translate("Generuj list przewozowy")}
                                                                </Button>

                                                                <Button onClick={() => {
                                                                    window.open("#/warehouse/shipping", "_self")
                                                                }}
                                                                        primary={this.state.completedStep >= 3}
                                                                >
                                                                    {TranslationController.translate("Zakończ")}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        justifyContent: "space-between"
                                                    }}>
                                                        <div style={{width: 10}}>
                                                        </div>
                                                        <div style={{marginTop: 10}}>
                                                            {this.state.completedStep < 3 &&
                                                                <div className={"eanScan-disabled"}></div>
                                                            }

                                                            <Button onClick={() => {
                                                                this.setState({
                                                                    dimmer: true
                                                                })

                                                                api().post("/api/orderService/createMmDocument", {
                                                                    items: this.props.data.sending,
                                                                    orderId: this.props.data.orderId,
                                                                    rowId: this.props.data._id,
                                                                    parlour: this.props.parlour
                                                                })
                                                                    .then((r) => {
                                                                        if (r.data && r.data.error) {
                                                                            this.setState({
                                                                                dimmer:false
                                                                            })

                                                                            return SnackBar(r.data.message, "error")
                                                                        } else {
                                                                            this.props.data.numberMM = r.data.numberMM;
                                                                            this.props.data.mmDocument = r.data.mmDocument;
                                                                            this.acceptOrder();

                                                                            this.setState({
                                                                                dimmer: false
                                                                            })
                                                                        }
                                                                    })
                                                            }}
                                                                    primary={this.state.completedStep >= 3}
                                                            >
                                                                {TranslationController.translate("Wystaw dokument MM")}
                                                            </Button>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </Step.Content>
                                </Step>
                            </React.Fragment>
                            }
                        </Step.Group>
                    </div>


                    {this.state.dimmer &&
                        <Dimmer active inverted style={{
                            position: "fixed",
                            zIndex: 99999
                        }}>
                            <Loader size='medium'>Proszę czekać... trwa synchronizacja.</Loader>
                        </Dimmer>
                    }
                </div>
            </React.Fragment>
        )
    }
}
