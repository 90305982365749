import React from "react"
import Button from "../../components/Button";
import {Form, Message} from "semantic-ui-react";
import SnackBar from "../../components/Snack";
import EsotiqInput from "../../components/EsotiqInput";

export default class OnlineComplaintRevertData extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            revertBankName: null,
            revertBankAccount: null
        }
    }

    componentDidMount() {
        this.props.accept({
            revertBankName: null,
            revertBankAccount: null
        })
    }

    render() {
        return (
            <div style={{
                width: 600
            }}>
                <Message
                    header={"Podaj dane dotyczące zwrotu"}
                    content={this.props.type.toLowerCase().includes("online") ?
                        "Zamówienie zostało opłacone online. Środki zostaną zwrócone na konto z którego dokonano płatności" :
                        ""
                    }
                    info
                />

                <div style={{marginBottom: 20}}></div>

                <Form onSubmit={() => {
                    this.props.accept({
                        revertBankName: this.state.revertBankName,
                        revertBankAccount: this.state.revertBankAccount
                    })

                    SnackBar("Zapisano pomyślnie.")
                }}>
                    <EsotiqInput
                        label={this.props.type.toLowerCase().includes("online") ? "(Opcjonalnie) " : "" + "Nazwa banku"}
                        placeholder={"Wpisz nazwę"}
                        width={"100%"}
                        onChange={value => {
                            this.state.revertBankName = value;

                            this.props.accept({
                                revertBankName: this.state.revertBankName,
                                revertBankAccount: this.state.revertBankAccount
                            })
                        }}
                    />

                    <EsotiqInput
                        label={this.props.type.toLowerCase().includes("online") ? "(Opcjonalnie) " : "" + "Numer konta na które ma zostać przelany zwrot"}
                        placeholder={"Wpisz numer"}
                        width={"100%"}
                        type={"number"}
                        onChange={value => {
                            this.state.revertBankAccount = value;

                            this.props.accept({
                                revertBankName: this.state.revertBankName,
                                revertBankAccount: this.state.revertBankAccount
                            })
                        }}
                    />

                    <div className={"revert-data-bank-addinfo"}>
                        * W przypadku gdy klient nie pamięta numeru konta, proszę zostawić puste pole i przejść do następnego kroku.
                    </div>

                    {/*<div style={{*/}
                    {/*    display: "flex",*/}
                    {/*    width: "100%",*/}
                    {/*    justifyContent: "flex-end"*/}
                    {/*}}>*/}
                    {/*    <Button style={{marginTop: 20}}>Zapisz</Button>*/}
                    {/*</div>*/}
                </Form>
            </div>
        );
    }
}
