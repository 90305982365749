import React from "react";
import PaperTitle from "../../components/PaperTitle";
import Grid from "@material-ui/core/Grid";
import DataPresentation from "../../components/DataPresentation";
import {DATA_OBJECT} from "../../controller/dataController";
import DisplayPrice from "../../components/display/DisplayPrice";
import {TranslationController} from "../../controller/menager";
import {formatDate} from "../../helpers/dateHelper";
import Button from "../../components/Button";
import {api, controlPanelApi, STATIC_URL} from "../../lib/axios";
import Confirm from "../../components/Confirm";
import LabelCreationWrapper from "./orderPreview/LabelCreationWrapper";
import SnackBar from "../../components/Snack";
import {PDFDownloadLink} from "@react-pdf/renderer";
import MultiDeliveryPDF from "./orderPreview/multiDeliveryPDF";
import PaperComponent from "../../components/Paper";

export default class DeliveryManagement extends React.Component {
    constructor(props) {
        super(props);


    }

    render() {
        const editState = this.props.editState;
        const orderData = this.props.orderData;

        return (
            <PaperComponent>
                <PaperTitle title={"Dostawa"}/>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <DataPresentation
                            label={"Metoda dostawy"}
                            data={this.props.shippingMethods[orderData.shippingMethodId] ? this.props.shippingMethods[orderData.shippingMethodId].name : null}
                            editable={editState}
                            options={this.props.shippingMethodsList.map(method => ({
                                text: method.externalId,
                                value: {
                                    id: method._id,
                                    title: method.name + (method.country ? ` (${method.country.toUpperCase()})` : null)
                                },
                                key: method._id
                            }))}
                            onChange={(value) => {
                                orderData.shippingMethod = value.title;
                                orderData.shippingMethodId = value.id;

                                if (this.props.state.orderData) {
                                    this.props.state.shippingMethodId = value.id
                                }

                                this.props.controller.getAvailablePaymentMethods();
                            }}
                        />
                        <DataPresentation
                            label={"Uwagi dla kuriera"}
                            data={orderData.shippingDescription || orderData.addressShipping.description}
                            editable={editState}
                            onChange={value => {
                                orderData.shippingDescription = value;
                                orderData.addressShipping.description = value;
                            }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <DataPresentation
                            label={"Miejsce docelowe paczki"}
                            data={orderData.shippingPoint ? orderData.shippingPoint.name : null}
                            editable={editState}
                            options={(orderData.shippingMethodId && ["60630e23fb914811cf65b65b", "6278fcbed7d55e7e55d5362d", "658bdb547ad6cbed1faabbab"].includes(orderData.shippingMethodId)) ? DATA_OBJECT.parlours.map(e => ({
                                key: e.id, text: `${e.id} [${e.name}]`, value: e.id
                            })) : null}
                            onChange={value => {
                                if (typeof orderData.shippingPoint === "string") {
                                    orderData.shippingPoint = {};
                                }

                                if (!orderData.shippingPoint) {
                                    orderData.shippingPoint = {}
                                }

                                if (value && value.length) {
                                    orderData.shippingPlace = value.toUpperCase();
                                    orderData.shippingPoint.name = value.toUpperCase();
                                } else {
                                    orderData.shippingPlace = value;
                                    orderData.shippingPoint.name = value;
                                }
                            }}
                        />

                        {(orderData.shippingPlaceAddress || (orderData.shippingPoint && orderData.shippingPoint.name)) &&
                            <DataPresentation
                                label={"Adres paczki"}
                                data={orderData.shippingPlaceAddress}
                                editable={editState}
                                onChange={value => {
                                    if (value && value.length) {
                                        orderData.shippingPlaceAddress = value.toUpperCase();
                                    } else {
                                        orderData.shippingPlaceAddress = value;
                                    }
                                }}
                            />
                        }

                        <DataPresentation
                            label={"Koszt przesyłki"}
                            data={editState ? orderData.shippingAmount : <DisplayPrice currency={orderData.currency} value={orderData.shippingAmount}/>}
                            editable={editState}
                            onChange={value => {
                                if (value && value.length) {
                                    orderData.price = parseFloat(orderData.price) - parseFloat(orderData.shippingAmount);
                                    orderData.shippingAmount = parseFloat(value);
                                    orderData.price = parseFloat(orderData.price) + parseFloat(orderData.shippingAmount);
                                }
                            }}
                        />
                    </Grid>
                </Grid>

                {(this.props.state.orderData && (this.props.isParlourOrder || this.props.state.orderData.country === "de" || this.props.internalOrder) && (this.props.state.orderData && this.props.state.orderData.orderService)) &&
                    <div className={"order-delivery-list"}>
                        {this.props.state.orderData.orderService.map((orderService, key) => {
                            if (!orderService.pickupNumber) return null;
                            return (
                                <div key={key}>
                                    <div className={"order-delivey-warpper"}>
                                        <div style={{
                                            marginLeft: 10
                                        }} className={"order-delivery-info-wrapper"}>
                                            {(orderService.pickupFile || orderService.pickupNumber) &&
                                                <React.Fragment>
                                                    {
                                                        this.props.state.orderData.country === "de"
                                                            ?
                                                            <div style={{
                                                                marginBottom: 10
                                                            }}>
                                                                <div className={"order-delivery-warpper-label-box"}>
                                                                    <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Data nadania paczki")}:</div>
                                                                    <div className={"order-delivery-warpper-value"}>{formatDate(orderService.pickupCreateDate).dateTimeSystem}</div>
                                                                </div>

                                                                <div className={"order-delivery-warpper-label-box"}>
                                                                    <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Numer paczki")}:</div>
                                                                    <a href={`https://www.dhl.de/de/privatkunden/dhl-sendungsverfolgung.html?piececode=${orderService.pickupNumber}`} target={"_blank"} className={"order-delivery-warpper-value"}>{orderService.pickupNumber}</a>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div style={{
                                                                marginBottom: 10
                                                            }}>
                                                                <div className={"order-delivery-warpper-label-box"}>
                                                                    <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Salon")}:</div>
                                                                    <div className={"order-delivery-warpper-value"}>{orderService.parlour}</div>
                                                                </div>

                                                                <div className={"order-delivery-warpper-label-box"}>
                                                                    <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Data nadania paczki")}:</div>
                                                                    <div className={"order-delivery-warpper-value"}>{formatDate(orderService.pickupCreateDate).dateTimeSystem}</div>
                                                                </div>

                                                                <div className={"order-delivery-warpper-label-box"}>
                                                                    <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Numer paczki")}:</div>
                                                                    <a href={orderService.provider === "gls" ? `https://gls-group.com/PL/pl/sledzenie-paczek?match=${orderService.pickupNumber}` : `https://tracktrace.dpd.com.pl/parcelDetails?typ=1&p1=${orderService.pickupNumber}`} target={"_blank"} className={"order-delivery-warpper-value"}>{orderService.pickupNumber}</a>
                                                                </div>
                                                            </div>
                                                    }
                                                </React.Fragment>
                                            }

                                            {(orderService.pickupFile || orderService.pickupNumber) &&
                                                <div style={{
                                                    marginTop: 5,
                                                    display: "flex",
                                                    justifycontent: "center"
                                                }}>
                                                    <Button secondary onClick={() => {
                                                        if (this.props.state.orderData.country === "de") {
                                                            window.open(orderService.pickupFile);
                                                        } else if (orderService.provider === "gls") {
                                                            const protocol = orderService.protocol ?? '';
                                                        api().get(`/api/orders/delivery/getLabel/${orderService.pickupNumber}/gls?format=smallPdf&parlour=${DATA_OBJECT.selected_parlour}&protocol=${protocol}`).then(
                                                        (result) => {
                                                        const arrayBuffer = Uint8Array.from(result.data.label.data).buffer;

                                                        const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                                                        const blobUrl = URL.createObjectURL(blob);
                                                        window.open(blobUrl, "_blank")
                                                    })
                                                    } else {
                                                            window.open(`${STATIC_URL}${orderService.pickupFile}`)
                                                        }
                                                    }}>
                                                        Pobierz etykietę
                                                    </Button>

                                                    <Button icon={"trash"} onClick={() => {
                                                        Confirm("Czy na pewno chcesz trwale usunąć ten list przewozowy? Konieczne będzie wygenerowanie nowgo przez salon.", null, () => {
                                                            api().post("/api/orderService/deletePickup",
                                                                {
                                                                    orderId: orderData.id,
                                                                    pickupNumber: orderService.pickupNumber,
                                                                    orderServiceId: orderService._id,
                                                                    userId: DATA_OBJECT["user"]["login"]
                                                                });

                                                            setTimeout(() => {
                                                                this.props.controller.hardRefresh();
                                                            }, 250)
                                                        })
                                                    }}/>

                                                    <Button icon={"edit"} onClick={() => {
                                                        this.props.setState({
                                                            packNumberChange: {
                                                                orderId: orderData.id,
                                                                pickupNumber: orderService.pickupNumber,
                                                                orderServiceId: orderService._id,
                                                                userId: DATA_OBJECT["user"]["login"],
                                                            }
                                                        });
                                                    }}/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }

                {(!this.props.isParlourOrder || true) &&
                    <div>
                        {(orderData.shippingPoint || (orderData.delivery && orderData.delivery.isCourier)) &&
                            <div className={"order-delivey-warpper"}>
                                <div style={{
                                    marginLeft: 10
                                }} className={"order-delivery-info-wrapper"}>
                                    {orderData.delivery && orderData.delivery.data && orderData.delivery.data.status === "created" &&
                                        <div style={{
                                            marginBottom: 10
                                        }}>
                                            <div className={"order-delivery-warpper-label-box"}>
                                                <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Data nadania paczki")}:</div>
                                                <div className={"order-delivery-warpper-value"}>{formatDate(orderData.delivery.createdTime).dateTimeSystem}</div>
                                            </div>

                                            <div className={"order-delivery-warpper-label-box"}>
                                                <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Numer paczki")}:</div>
                                                <a href={`https://inpost.pl/sledzenie-przesylek?number=${orderData.delivery.tracking_number}`} target={"_blank"} className={"order-delivery-warpper-value"}>{orderData.delivery.tracking_number}</a>
                                            </div>

                                            {orderData.delivery.user &&
                                                <div className={"order-delivery-warpper-label-box"}>
                                                    <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Użytkownik")}:</div>
                                                    <div className={"order-delivery-warpper-value"}>{orderData.delivery.user}</div>
                                                </div>
                                            }
                                        </div>
                                    }

                                    { (this.props.shippingMethods[orderData.shippingMethodId] && this.props.shippingMethods[orderData.shippingMethodId].provider && (this.props.shippingMethods[orderData.shippingMethodId].provider.id.toLowerCase() === "inpost") && this.props.shippingMethods[orderData.shippingMethodId]?.marketplace) &&
                                        <>
                                            {orderData.delivery && orderData.delivery.data && orderData.delivery.data.pickupFile &&
                                                <div style={{
                                                    marginBottom: 10
                                                }}>
                                                    <div className={"order-delivery-warpper-label-box"}>
                                                        <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Data nadania paczki")}:</div>
                                                        <div className={"order-delivery-warpper-value"}>{formatDate(orderData.delivery.createdTime).dateTimeSystem}</div>
                                                    </div>

                                                    {!orderData.shippingMethod.toLowerCase().includes('allegro one') && <div className={"order-delivery-warpper-label-box"}>
                                                        <div
                                                            className={"order-delivery-wrapper-label"}>{TranslationController.translate("Numer paczki")}:
                                                        </div>
                                                        <a href={`https://inpost.pl/sledzenie-przesylek?number=${orderData.delivery.data.packageNumber}`}
                                                           target={"_blank"}
                                                           className={"order-delivery-warpper-value"}>{orderData.delivery.data.packageNumber}</a>
                                                    </div>}

                                                    {orderData.delivery.user &&
                                                        <div className={"order-delivery-warpper-label-box"}>
                                                            <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Użytkownik")}:</div>
                                                            <div className={"order-delivery-warpper-value"}>{orderData.delivery.user}</div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            <div className={"flex"}>
                                                <Button secondary onClick={() =>
                                                    new LabelCreationWrapper({
                                                        provider: this.props,
                                                        orderData: orderData
                                                    })
                                                        .RegisterCallbackAction(() => {
                                                            api().post("/api/orders/delivery/create",
                                                                {
                                                                    orderId: orderData.id.toString(),
                                                                    provider: 'baselinker',
                                                                    packSize: "A",
                                                                    targetPoint: orderData.shippingPoint.id || orderData.shippingPoint.name,
                                                                    user: DATA_OBJECT.user_login,
                                                                    marketplace: orderData.allegroData.marketplace,
                                                                })
                                                                .then(result => {
                                                                    if (result.data.error) {
                                                                        this.props.setState({
                                                                            dimmer: false
                                                                        });

                                                                        return SnackBar(result.data.message, "error")
                                                                    } else {
                                                                        setTimeout(() => {
                                                                            this.props.controller.hardRefresh((orderData) => {
                                                                                const courierCode = orderData.allegroData.courierCode || "allegrokurier";
                                                                                controlPanelApi.get(`/baselinker/get-label/${courierCode}/${result.data.packageId}`)
                                                                                    .then(data => {
                                                                                        const arrayBuffer = Uint8Array.from(data.data.label.data).buffer;

                                                                                        const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                                                                                        const blobUrl = URL.createObjectURL(blob);
                                                                                        window.open(blobUrl, "_blank")});
                                                                            });

                                                                            SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                                                        }, 2000)
                                                                    }
                                                                })
                                                        })
                                                        .CheckForPersonalizedProducts()
                                                        .Create()
                                                }>
                                                    Nadaj paczkę gabaryt A
                                                </Button>

                                                <Button secondary onClick={() =>
                                                    new LabelCreationWrapper({
                                                        provider: this.props,
                                                        orderData: orderData
                                                    })
                                                        .RegisterCallbackAction(() => {
                                                            api()
                                                                .post("/api/orders/delivery/create",
                                                                    {
                                                                        provider: "baselinker",
                                                                        orderId: orderData.id.toString(),
                                                                        packSize: "B",
                                                                        targetPoint: orderData.shippingPoint.id || orderData.shippingPoint.name,
                                                                        user: DATA_OBJECT.user_login,
                                                                        marketplace: orderData.allegroData.marketplace,
                                                                    })
                                                                .then(result => {
                                                                    if (result.data.error) {
                                                                        this.props.setState({
                                                                            dimmer: false
                                                                        });

                                                                        return SnackBar(result.data.message, "error");
                                                                    } else {
                                                                        setTimeout(() => {
                                                                            this.props.controller.hardRefresh((orderData) => {
                                                                                const courierCode = orderData.allegroData.courierCode || "allegrokurier";
                                                                                controlPanelApi.get(`/baselinker/get-label/${courierCode}/${result.data.packageId}`)
                                                                                    .then(data => {
                                                                                        const arrayBuffer = Uint8Array.from(data.data.label.data).buffer;

                                                                                        const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                                                                                        const blobUrl = URL.createObjectURL(blob);
                                                                                        window.open(blobUrl, "_blank")});
                                                                            });

                                                                            SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                                                        }, 2000)
                                                                    }
                                                                })
                                                        })
                                                        .CheckForPersonalizedProducts()
                                                        .Create()
                                                }>
                                                    Nadaj paczkę gabaryt B
                                                </Button>
                                            </div>

                                            {orderData.delivery && orderData.delivery.data && orderData.delivery.status === "created" &&
                                                <div style={{
                                                    marginTop: 5
                                                }}>
                                                    <Button
                                                        onClick={() => {
                                                            const courierCode = orderData.allegroData.courierCode || "allegrokurier";
                                                            controlPanelApi.get(`/baselinker/get-label/${courierCode}/${orderData.delivery.data.packageId}`)
                                                                .then(data => {
                                                                    const arrayBuffer = Uint8Array.from(data.data.label.data).buffer;

                                                                    const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                                                                    const blobUrl = URL.createObjectURL(blob);
                                                                    window.open(blobUrl, "_blank")});
                                                        }}
                                                        primary
                                                    >
                                                        Pobierz etykietę
                                                    </Button>
                                                </div>
                                            }
                                        </>
                                    }

                                    {(this.props.shippingMethods[orderData.shippingMethodId] && this.props.shippingMethods[orderData.shippingMethodId].provider && this.props.shippingMethods[orderData.shippingMethodId].provider.id === "inpost" && !this.props.shippingMethods[orderData.shippingMethodId]?.marketplace) &&
                                        <div className={"flex"}>
                                            <Button primary onClick={() =>
                                                new LabelCreationWrapper({
                                                    provider: this.props,
                                                    orderData: orderData
                                                })
                                                    .RegisterCallbackAction(() => {
                                                        api().post("/api/orders/delivery/create",
                                                            {
                                                                orderId: orderData.id,
                                                                provider: "inpost",
                                                                packSize: "A",
                                                                targetPoint: orderData.shippingPoint.id || orderData.shippingPoint.name,
                                                                user: DATA_OBJECT.user_login,
                                                            })
                                                            .then(result => {
                                                                if (result.data.error) {
                                                                    this.props.setState({
                                                                        dimmer: false
                                                                    });

                                                                    return SnackBar(result.data.message, "error")
                                                                } else {
                                                                    setTimeout(() => {
                                                                        this.props.controller.hardRefresh((orderData) => {
                                                                            api().post("/api/orders/delivery/getLabel", {
                                                                                orderId: orderData.id,
                                                                                provider: "inpost"
                                                                            })
                                                                                .then(result => {
                                                                                    if (result.data) {
                                                                                        if (result.data.error) {
                                                                                            this.props.setState({
                                                                                                dimmer: false
                                                                                            });

                                                                                            return SnackBar(JSON.stringify(result.data.message, undefined, true), "error")
                                                                                        }

                                                                                        this.props.setState({
                                                                                            dimmer: false
                                                                                        });

                                                                                        fetch(result.data.urlParams.url, {
                                                                                            method: result.data.urlParams.method,
                                                                                            headers: result.data.urlParams.headers
                                                                                        })
                                                                                            .then(res => {
                                                                                                return res.blob()
                                                                                            })
                                                                                            .then(blob => {
                                                                                                const file = window.URL.createObjectURL(blob);

                                                                                                window.open(file, "_blank")
                                                                                            })
                                                                                    }
                                                                                })
                                                                        });

                                                                        SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                                                    }, 2000)
                                                                }
                                                            })
                                                    })
                                                    .CheckForPersonalizedProducts()
                                                    .Create()
                                            }>
                                                Nadaj paczkę gabaryt A
                                            </Button>

                                            <Button primary onClick={() =>
                                                new LabelCreationWrapper({
                                                    provider: this.props,
                                                    orderData: orderData
                                                })
                                                    .RegisterCallbackAction(() => {
                                                        api()
                                                            .post("/api/orders/delivery/create",
                                                                {
                                                                    orderId: orderData.id,
                                                                    provider: "inpost",
                                                                    packSize: "B",
                                                                    targetPoint: orderData.shippingPoint.id || orderData.shippingPoint.name,
                                                                    user: DATA_OBJECT.user_login,
                                                                })
                                                            .then(result => {
                                                                if (result.data.error) {
                                                                    this.props.setState({
                                                                        dimmer: false
                                                                    });

                                                                    return SnackBar(result.data.message, "error");
                                                                } else {
                                                                    setTimeout(() => {
                                                                        this.props.controller.hardRefresh((orderData) => {
                                                                            api().post("/api/orders/delivery/getLabel", {
                                                                                orderId: orderData.id,
                                                                                provider: "inpost"
                                                                            })
                                                                                .then(result => {
                                                                                    if (result.data) {
                                                                                        if (result.data.error) {
                                                                                            this.props.setState({
                                                                                                dimmer: false
                                                                                            });

                                                                                            return SnackBar(JSON.stringify(result.data.message, undefined, true), "error")
                                                                                        }

                                                                                        this.props.setState({
                                                                                            dimmer: false
                                                                                        });

                                                                                        fetch(result.data.urlParams.url, {
                                                                                            method: result.data.urlParams.method,
                                                                                            headers: result.data.urlParams.headers
                                                                                        })
                                                                                            .then(res => {
                                                                                                return res.blob()
                                                                                            })
                                                                                            .then(blob => {
                                                                                                const file = window.URL.createObjectURL(blob);

                                                                                                window.open(file, "_blank")
                                                                                            })
                                                                                    }
                                                                                })
                                                                        });

                                                                        SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                                                    }, 2000)
                                                                }
                                                            })
                                                    })
                                                    .CheckForPersonalizedProducts()
                                                    .Create()
                                            }>
                                                Nadaj paczkę gabaryt B
                                            </Button>
                                        </div>
                                    }

                                    {orderData.delivery && orderData.delivery.data && orderData.delivery.data.status === "created" && !this.props.shippingMethods[orderData.shippingMethodId]?.marketplace &&
                                        <div style={{
                                            marginTop: 5
                                        }}>
                                            <Button
                                                onClick={() => {
                                                    api().post("/api/orders/delivery/getLabel", {orderId: orderData.id, provider: "inpost"})
                                                        .then(result => {
                                                            if (result.data) {
                                                                if (result.data.error) {
                                                                    return SnackBar(JSON.stringify(result.data.message, undefined, true), "error")
                                                                }

                                                                fetch(result.data.urlParams.url, {
                                                                    method: result.data.urlParams.method,
                                                                    headers: result.data.urlParams.headers
                                                                })
                                                                    .then(res => {
                                                                        return res.blob()
                                                                    })
                                                                    .then(blob => {
                                                                        const file = window.URL.createObjectURL(blob);

                                                                        window.open(file, "_blank")
                                                                    })
                                                            }
                                                        })
                                                }}
                                                secondary
                                            >
                                                Pobierz etykietę
                                            </Button>

                                            <Button icon={"trash"} onClick={() => {
                                                Confirm("Czy na pewno chcesz trwale usunąć ten list przewozowy? Konieczne będzie wygenerowanie nowgo przez salon.", null, () => {
                                                    api().post("/api/orderService/deletePickup",
                                                        {
                                                            orderId: orderData.id,
                                                            pickupNumber: orderData.delivery.pickupNumber || orderData.delivery.data.id,
                                                            orderServiceId: null,
                                                            userId: DATA_OBJECT["user"]["login"]
                                                        });

                                                    setTimeout(() => {
                                                        this.props.controller.hardRefresh();
                                                    }, 250)
                                                })
                                            }}/>

                                            <Button icon={"edit"} onClick={() => {
                                                this.props.setState({
                                                    packNumberChange: {
                                                        orderId: orderData.id,
                                                        pickupNumber: orderData.delivery.pickupNumber || orderData.delivery.data.id,
                                                        orderServiceId: null,
                                                        userId: DATA_OBJECT["user"]["login"],
                                                        provider: "inpost"
                                                    }
                                                });
                                            }}/>

                                            {orderData.delivery && orderData.delivery.multiDelivery &&
                                                <div style={{
                                                    marginTop: 5
                                                }}>
                                                    <PDFDownloadLink
                                                        document={<MultiDeliveryPDF orders={orderData.delivery.multiDelivery}/>}
                                                    >
                                                        {({ blob, url, loading, error }) => {
                                                            return (
                                                                <Button
                                                                    loading={loading}
                                                                    disabled={loading}
                                                                    secondary
                                                                    onClick={() => window.open(url, '_blank')}
                                                                >
                                                                    Etykieta zbiorcza
                                                                </Button>
                                                            )
                                                        }}
                                                    </PDFDownloadLink>
                                                </div>
                                            }
                                        </div>
                                    }

                                    {orderData.delivery && orderData.delivery.data && orderData.delivery.data.status === "created" && this.props.shippingMethods[orderData.shippingMethodId]?.marketplace &&
                                        <div style={{
                                            marginTop: 5
                                        }}>
                                            <Button
                                                onClick={() => {
                                                    const courierCode = orderData.allegroData.courierCode || 'allegrokurier';
                                                    controlPanelApi.get(`/baselinker/get-label/${courierCode}/${orderData.delivery.data.packageId}`)
                                                        .then(data => {
                                                            const arrayBuffer = Uint8Array.from(data.data.label.data).buffer;

                                                            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                                                            const blobUrl = URL.createObjectURL(blob);
                                                            window.open(blobUrl, "_blank")});
                                                }}
                                                primary
                                            >
                                                Pobierz etykietę
                                            </Button>
                                        </div>
                                    }
                                </div>
                            </div>
                        }

                        {(this.props.shippingMethods[orderData.shippingMethodId] && this.props.shippingMethods[orderData.shippingMethodId].provider && this.props.shippingMethods[orderData.shippingMethodId].provider.id === "inpost_courier" && !this.props.shippingMethods[orderData.shippingMethodId]?.marketplace) &&
                            <div className={"flex"}>
                                <Button primary onClick={() =>
                                    new LabelCreationWrapper({
                                        provider: this.props,
                                        orderData: orderData
                                    })
                                        .RegisterCallbackAction(() => {
                                            api().post("/api/orders/delivery/create",
                                                {
                                                    orderId: orderData.id,
                                                    provider: "inpost",
                                                    packSize: "A",
                                                    user: DATA_OBJECT.user_login,
                                                    isCourier: true
                                                })
                                                .then(result => {
                                                    if (result.data.error) {
                                                        this.props.setState({
                                                            dimmer: false
                                                        });

                                                        return SnackBar(result.data.message, "error")
                                                    } else {
                                                        setTimeout(() => {
                                                            this.props.controller.hardRefresh((orderData) => {
                                                                api().post("/api/orders/delivery/getLabel", {
                                                                    orderId: orderData.id,
                                                                    provider: "inpost"
                                                                })
                                                                    .then(result => {
                                                                        if (result.data) {
                                                                            if (result.data.error) {
                                                                                this.props.setState({
                                                                                    dimmer: false
                                                                                });

                                                                                return SnackBar(JSON.stringify(result.data.message, undefined, true), "error")
                                                                            }

                                                                            this.props.setState({
                                                                                dimmer: false
                                                                            });

                                                                            fetch(result.data.urlParams.url, {
                                                                                method: result.data.urlParams.method,
                                                                                headers: result.data.urlParams.headers
                                                                            })
                                                                                .then(res => {
                                                                                    return res.blob()
                                                                                })
                                                                                .then(blob => {
                                                                                    const file = window.URL.createObjectURL(blob);

                                                                                    window.open(file.toString(), "_blank")
                                                                                })
                                                                        }
                                                                    })
                                                            });

                                                            SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                                        }, 5000)
                                                    }
                                                })
                                        })
                                        .CheckForPersonalizedProducts()
                                        .Create()
                                }>
                                    Nadaj paczkę gabaryt A Kurier
                                </Button>
                                <Button primary onClick={() =>
                                    new LabelCreationWrapper({
                                        provider: this.props,
                                        orderData: orderData
                                    })
                                        .RegisterCallbackAction(() => {
                                            api().post("/api/orders/delivery/create",
                                                {
                                                    orderId: orderData.id,
                                                    provider: "inpost",
                                                    packSize: "B",
                                                    user: DATA_OBJECT.user_login,
                                                    isCourier: true
                                                })
                                                .then(result => {
                                                    if (result.data.error) {
                                                        this.props.setState({
                                                            dimmer: false
                                                        });

                                                        return SnackBar(result.data.message, "error")
                                                    } else {
                                                        setTimeout(() => {
                                                            this.props.controller.hardRefresh((orderData) => {
                                                                api().post("/api/orders/delivery/getLabel", {
                                                                    orderId: orderData.id,
                                                                    provider: "inpost"
                                                                })
                                                                    .then(result => {
                                                                        if (result.data) {
                                                                            if (result.data.error) {
                                                                                this.props.setState({
                                                                                    dimmer: false
                                                                                });

                                                                                return SnackBar(JSON.stringify(result.data.message, undefined, true), "error")
                                                                            }

                                                                            this.props.setState({
                                                                                dimmer: false
                                                                            });

                                                                            fetch(result.data.urlParams.url, {
                                                                                method: result.data.urlParams.method,
                                                                                headers: result.data.urlParams.headers
                                                                            })
                                                                                .then(res => {
                                                                                    return res.blob()
                                                                                })
                                                                                .then(blob => {
                                                                                    const file = window.URL.createObjectURL(blob);

                                                                                    window.open(file, "_blank")
                                                                                })
                                                                        }
                                                                    })
                                                            });

                                                            SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                                        }, 5000)
                                                    }
                                                })
                                        })
                                        .CheckForPersonalizedProducts()
                                        .Create()
                                }>
                                    Nadaj paczkę gabaryt B Kurier
                                </Button>
                            </div>
                        }

                        { (this.props.shippingMethods[orderData.shippingMethodId] && this.props.shippingMethods[orderData.shippingMethodId].provider && (this.props.shippingMethods[orderData.shippingMethodId].provider.id.toLowerCase() === "inpost_courier") && this.props.shippingMethods[orderData.shippingMethodId]?.marketplace) &&
                            <>
                                {orderData.delivery && orderData.delivery.data && orderData.delivery.data.pickupFile &&
                                    <div style={{
                                        marginBottom: 10
                                    }}>
                                        <div className={"order-delivery-warpper-label-box"}>
                                            <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Data nadania paczki")}:</div>
                                            <div className={"order-delivery-warpper-value"}>{formatDate(orderData.delivery.createdTime).dateTimeSystem}</div>
                                        </div>

                                        {!orderData.shippingMethod.toLowerCase().includes('allegro one') && <div className={"order-delivery-warpper-label-box"}>
                                            <div
                                                className={"order-delivery-wrapper-label"}>{TranslationController.translate("Numer paczki")}:
                                            </div>
                                            <a href={`https://inpost.pl/sledzenie-przesylek?number=${orderData.delivery.data.packageNumber}`}
                                               target={"_blank"}
                                               className={"order-delivery-warpper-value"}>{orderData.delivery.data.packageNumber}</a>
                                        </div>}

                                        {orderData.delivery.user &&
                                            <div className={"order-delivery-warpper-label-box"}>
                                                <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Użytkownik")}:</div>
                                                <div className={"order-delivery-warpper-value"}>{orderData.delivery.user}</div>
                                            </div>
                                        }
                                    </div>
                                }
                                <div className={"flex"}>
                                    <Button secondary onClick={() =>
                                        new LabelCreationWrapper({
                                            provider: this.props,
                                            orderData: orderData
                                        })
                                            .RegisterCallbackAction(() => {
                                                api().post("/api/orders/delivery/create",
                                                    {
                                                        orderId: orderData.id.toString(),
                                                        provider: 'baselinker',
                                                        packSize: "A",
                                                        targetPoint: orderData.shippingPoint.id || orderData.shippingPoint.name,
                                                        user: DATA_OBJECT.user_login,
                                                        marketplace: orderData.allegroData.marketplace,
                                                    })
                                                    .then(result => {
                                                        if (result.data.error) {
                                                            this.props.setState({
                                                                dimmer: false
                                                            });

                                                            return SnackBar(result.data.message, "error")
                                                        } else {
                                                            setTimeout(() => {
                                                                this.props.controller.hardRefresh((orderData) => {
                                                                    const courierCode = orderData.allegroData.courierCode || "allegrokurier";
                                                                    controlPanelApi.get(`/baselinker/get-label/${courierCode}/${result.data.packageId}`)
                                                                        .then(data => {
                                                                            const arrayBuffer = Uint8Array.from(data.data.label.data).buffer;

                                                                            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                                                                            const blobUrl = URL.createObjectURL(blob);
                                                                            window.open(blobUrl, "_blank")});
                                                                });

                                                                SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                                            }, 2000)
                                                        }
                                                    })
                                            })
                                            .CheckForPersonalizedProducts()
                                            .Create()
                                    }>
                                        Nadaj paczkę gabaryt A
                                    </Button>

                                    <Button secondary onClick={() =>
                                        new LabelCreationWrapper({
                                            provider: this.props,
                                            orderData: orderData
                                        })
                                            .RegisterCallbackAction(() => {
                                                api()
                                                    .post("/api/orders/delivery/create",
                                                        {
                                                            provider: "baselinker",
                                                            orderId: orderData.id.toString(),
                                                            packSize: "B",
                                                            targetPoint: orderData.shippingPoint.id || orderData.shippingPoint.name,
                                                            user: DATA_OBJECT.user_login,
                                                            marketplace: orderData.allegroData.marketplace,
                                                        })
                                                    .then(result => {
                                                        if (result.data.error) {
                                                            this.props.setState({
                                                                dimmer: false
                                                            });

                                                            return SnackBar(result.data.message, "error");
                                                        } else {
                                                            setTimeout(() => {
                                                                this.props.controller.hardRefresh((orderData) => {
                                                                    const courierCode = orderData.allegroData.courierCode || "allegrokurier";
                                                                    controlPanelApi.get(`/baselinker/get-label/${courierCode}/${result.data.packageId}`)
                                                                        .then(data => {
                                                                            const arrayBuffer = Uint8Array.from(data.data.label.data).buffer;

                                                                            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                                                                            const blobUrl = URL.createObjectURL(blob);
                                                                            window.open(blobUrl, "_blank")});
                                                                });

                                                                SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                                            }, 2000)
                                                        }
                                                    })
                                            })
                                            .CheckForPersonalizedProducts()
                                            .Create()
                                    }>
                                        Nadaj paczkę gabaryt B
                                    </Button>
                                </div>

                                {orderData.delivery && orderData.delivery.data && orderData.delivery.status === "created" &&
                                    <div style={{
                                        marginTop: 5
                                    }}>
                                        <Button
                                            onClick={() => {
                                                const courierCode = orderData.allegroData.courierCode || 'allegrokurier';
                                                controlPanelApi.get(`/baselinker/get-label/${courierCode}/${orderData.delivery.data.packageId}`)
                                                    .then(data => {
                                                        const arrayBuffer = Uint8Array.from(data.data.label.data).buffer;

                                                        const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                                                        const blobUrl = URL.createObjectURL(blob);
                                                        window.open(blobUrl, "_blank")});
                                            }}
                                            primary
                                        >
                                            Pobierz etykietę
                                        </Button>
                                    </div>
                                }
                            </>
                        }

                        {(orderData.shippingPoint && (this.props.shippingMethods[orderData.shippingMethodId] && this.props.shippingMethods[orderData.shippingMethodId].provider && this.props.shippingMethods[orderData.shippingMethodId].provider.id === "ruch")) &&
                            <div className={"order-delivey-warpper"}>
                                <div style={{
                                    marginLeft: 10
                                }} className={"order-delivery-info-wrapper"}>
                                    {orderData.delivery && orderData.delivery.data &&
                                        <div style={{
                                            marginBottom: 10
                                        }}>
                                            <div className={"order-delivery-warpper-label-box"}>
                                                <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Data nadania paczki")}:</div>
                                                <div className={"order-delivery-warpper-value"}>{formatDate(orderData.delivery.createdTime).dateTimeSystem}</div>
                                            </div>

                                            <div className={"order-delivery-warpper-label-box"}>
                                                <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Numer paczki")}:</div>

                                                <a href={`https://www.orlenpaczka.pl/sledz-paczke/?numer=${this.props.shippingMethods[orderData.shippingMethodId]?.marketplace ? orderData.delivery.data.packageNumber : orderData.delivery.data.data.GenerateLabelBusinessPack.PackCode_RUCH}`} target={"_blank"} className={"order-delivery-warpper-value"}>{this.props.shippingMethods[orderData.shippingMethodId]?.marketplace ? orderData.delivery.data.packageNumber : orderData.delivery.data.data.GenerateLabelBusinessPack.PackCode_RUCH}</a>
                                            </div>

                                            {orderData.delivery.user &&
                                                <div className={"order-delivery-warpper-label-box"}>
                                                    <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Użytkownik")}:</div>
                                                    <div className={"order-delivery-warpper-value"}>{orderData.delivery.user}</div>
                                                </div>
                                            }
                                        </div>
                                    }

                                    {(this.props.shippingMethods[orderData.shippingMethodId] && this.props.shippingMethods[orderData.shippingMethodId].provider && this.props.shippingMethods[orderData.shippingMethodId].provider.id.toLowerCase() === "ruch" && this.props.shippingMethods[orderData.shippingMethodId]?.marketplace) &&
                                        <div className={"flex"}>
                                            <Button secondary onClick={() =>
                                                new LabelCreationWrapper({
                                                    provider: this.props,
                                                    orderData: orderData
                                                })
                                                    .RegisterCallbackAction(() => {
                                                        api()
                                                            .post("/api/orders/delivery/create",
                                                                {
                                                                    orderId: orderData.id.toString(),
                                                                    provider: "baselinker",
                                                                    targetPoint: orderData.shippingPoint.id || orderData.shippingPoint.name,
                                                                    user: DATA_OBJECT.user_login,
                                                                    packSize: "S"
                                                                })
                                                            .then(result => {
                                                                this.props.setState({
                                                                    dimmer: false
                                                                });

                                                                if (result.data.error) {
                                                                    return SnackBar(result.data.message, "error")
                                                                } else {
                                                                    const courierCode = orderData.allegroData.courierCode || "allegrokurier";
                                                                    controlPanelApi.get(`/baselinker/get-label/${courierCode}/${result.data.packageId}`)
                                                                        .then(data => {
                                                                            const arrayBuffer = Uint8Array.from(data.data.label.data).buffer;

                                                                            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                                                                            const blobUrl = URL.createObjectURL(blob);
                                                                            window.open(blobUrl, "_blank")});

                                                                    SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                                                }
                                                            })
                                                    })
                                                    .CheckForPersonalizedProducts()
                                                    .Create()
                                            }>
                                                Nadaj paczkę gabaryt S
                                            </Button>

                                            <Button secondary onClick={() =>
                                                new LabelCreationWrapper({
                                                    provider: this.props,
                                                    orderData: orderData
                                                })
                                                    .RegisterCallbackAction(() => {
                                                        api()
                                                            .post("/api/orders/delivery/create",
                                                                {
                                                                    orderId: orderData.id.toString(),
                                                                    provider: "baselinker",
                                                                    targetPoint: orderData.shippingPoint.id || orderData.shippingPoint.name,
                                                                    user: DATA_OBJECT.user_login,
                                                                    packSize: "M"
                                                                })
                                                            .then(result => {
                                                                this.props.setState({
                                                                    dimmer: false
                                                                });

                                                                if (result.data.error) {
                                                                    return SnackBar(result.data.message, "error")
                                                                } else {
                                                                    const courierCode = orderData.allegroData.courierCode || "allegrokurier";
                                                                    controlPanelApi.get(`/baselinker/get-label/${courierCode}/${result.data.packageId}`)
                                                                        .then(data => {
                                                                            const arrayBuffer = Uint8Array.from(data.data.label.data).buffer;

                                                                            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                                                                            const blobUrl = URL.createObjectURL(blob);
                                                                            window.open(blobUrl, "_blank")});

                                                                    SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                                                }
                                                            })
                                                    })
                                                    .CheckForPersonalizedProducts()
                                                    .Create()
                                            }>
                                                Nadaj paczkę gabaryt M
                                            </Button>
                                        </div>
                                    }

                                    {orderData.delivery && orderData.delivery.data && orderData.delivery.data.pickupFile && this.props.shippingMethods[orderData.shippingMethodId]?.marketplace &&
                                        <div style={{
                                            marginTop: 5
                                        }}>
                                            <Button primary onClick={() => {
                                                const courierCode = orderData.allegroData.courierCode || "allegrokurier";
                                                controlPanelApi.get(`/baselinker/get-label/${courierCode}/${orderData.delivery.data.packageId}`)
                                                    .then(data => {
                                                        const arrayBuffer = Uint8Array.from(data.data.label.data).buffer;

                                                        const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                                                        const blobUrl = URL.createObjectURL(blob);
                                                        window.open(blobUrl, "_blank")});
                                            }}>
                                                Pobierz etykietę
                                            </Button>
                                        </div>
                                    }

                                    {(this.props.shippingMethods[orderData.shippingMethodId] && this.props.shippingMethods[orderData.shippingMethodId].provider && this.props.shippingMethods[orderData.shippingMethodId].provider.id === "ruch" && !this.props.shippingMethods[orderData.shippingMethodId]?.marketplace) &&
                                        <div className={"flex"}>
                                            <Button primary onClick={() =>
                                                new LabelCreationWrapper({
                                                    provider: this.props,
                                                    orderData: orderData
                                                })
                                                    .RegisterCallbackAction(() => {
                                                        api()
                                                            .post("/api/orders/delivery/create",
                                                                {
                                                                    orderId: orderData.id,
                                                                    provider: "ruch",
                                                                    targetPoint: orderData.shippingPoint.id || orderData.shippingPoint.name,
                                                                    user: DATA_OBJECT.user_login,
                                                                    packSize: "S"
                                                                })
                                                            .then(result => {
                                                                this.props.setState({
                                                                    dimmer: false
                                                                });

                                                                if (result.data.error) {
                                                                    return SnackBar(result.data.message, "error")
                                                                } else {
                                                                    this.props.controller.hardRefresh((orderData) => {
                                                                        window.open(`${STATIC_URL}${orderData.delivery.data.fileName}`)
                                                                    });

                                                                    SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                                                }
                                                            })
                                                    })
                                                    .CheckForPersonalizedProducts()
                                                    .Create()
                                            }>
                                                Nadaj paczkę gabaryt S
                                            </Button>

                                            <Button primary onClick={() =>
                                                new LabelCreationWrapper({
                                                    provider: this.props,
                                                    orderData: orderData
                                                })
                                                    .RegisterCallbackAction(() => {
                                                        api()
                                                            .post("/api/orders/delivery/create",
                                                                {
                                                                    orderId: orderData.id,
                                                                    provider: "ruch",
                                                                    targetPoint: orderData.shippingPoint.id || orderData.shippingPoint.name,
                                                                    user: DATA_OBJECT.user_login,
                                                                    packSize: "M"
                                                                })
                                                            .then(result => {
                                                                this.props.setState({
                                                                    dimmer: false
                                                                });

                                                                if (result.data.error) {
                                                                    return SnackBar(result.data.message, "error")
                                                                } else {
                                                                    this.props.controller.hardRefresh((orderData) => {
                                                                        window.open(`${STATIC_URL}${orderData.delivery.data.fileName}`)
                                                                    });

                                                                    SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                                                }
                                                            })
                                                    })
                                                    .CheckForPersonalizedProducts()
                                                    .Create()
                                            }>
                                                Nadaj paczkę gabaryt M
                                            </Button>
                                        </div>
                                    }

                                    {orderData.delivery && orderData.delivery.data && orderData.delivery.data.fileName &&
                                        <div style={{
                                            marginTop: 5
                                        }}>
                                            <Button secondary onClick={() => {
                                                window.open(`${STATIC_URL}${orderData.delivery.data.fileName}`)
                                            }}>
                                                Pobierz etykietę
                                            </Button>

                                            <Button icon={"trash"} onClick={() => {
                                                Confirm("Czy na pewno chcesz trwale usunąć ten list przewozowy? Konieczne będzie wygenerowanie nowgo przez salon.", null, () => {
                                                    api().post("/api/orderService/deletePickup",
                                                        {
                                                            orderId: orderData.id,
                                                            pickupNumber: orderData.delivery.pickupNumber,
                                                            orderServiceId: null,
                                                            userId: DATA_OBJECT["user"]["login"]
                                                        });

                                                    setTimeout(() => {
                                                        this.props.controller.hardRefresh();
                                                    }, 250)
                                                })
                                            }}/>

                                            <Button icon={"edit"} onClick={() => {
                                                this.props.setState({
                                                    packNumberChange: {
                                                        orderId: orderData.id,
                                                        pickupNumber: orderData.delivery.pickupNumber,
                                                        orderServiceId: null,
                                                        userId: DATA_OBJECT["user"]["login"],
                                                    }
                                                });
                                            }}/>
                                        </div>
                                    }
                                </div>
                            </div>
                        }

                        {(this.props.shippingMethods[orderData.shippingMethodId] && this.props.shippingMethods[orderData.shippingMethodId].provider && this.props.shippingMethods[orderData.shippingMethodId].provider.id === "gls" && !this.props.shippingMethods[orderData.shippingMethodId]?.marketplace) &&
                            <div className={"order-delivey-warpper"}>
                                <div style={{
                                    marginLeft: 10
                                }} className={"order-delivery-info-wrapper"}>
                                    {orderData.delivery && orderData.delivery.pickupNumber &&
                                        <div style={{
                                            marginBottom: 10
                                        }}>
                                            <div className={"order-delivery-warpper-label-box"}>
                                                <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Data nadania paczki")}:</div>
                                                <div className={"order-delivery-warpper-value"}>{formatDate(orderData.delivery.createdTime).dateTimeSystem}</div>
                                            </div>

                                            <div className={"order-delivery-warpper-label-box"}>
                                                <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Numer paczki")}:</div>
                                                <a href={`https://gls-group.com/PL/pl/sledzenie-paczek?match=${orderData.delivery.pickupNumber}`} target={"_blank"} className={"order-delivery-warpper-value"}>{orderData.delivery.pickupNumber}</a>
                                            </div>

                                            {orderData.delivery.user &&
                                                <div className={"order-delivery-warpper-label-box"}>
                                                    <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Użytkownik")}:</div>
                                                    <div className={"order-delivery-warpper-value"}>{orderData.delivery.user}</div>
                                                </div>
                                            }
                                        </div>
                                    }

                                    <div className={"flex"}>
                                        <Button primary onClick={() =>
                                            new LabelCreationWrapper({
                                                provider: this.props,
                                                orderData: orderData
                                            })
                                            .RegisterCallbackAction(() => {
                                                api()
                                                .post("/api/orders/delivery/create",
                                                    {
                                                        orderId: orderData.id,
                                                        provider: "gls",
                                                        user: DATA_OBJECT.user_login,
                                                        parlour: DATA_OBJECT.selected_parlour,
                                                    })
                                                .then(result => {
                                                    this.props.setState({
                                                        dimmer: false
                                                    });

                                                    if (result.data.message) {
                                                        return SnackBar(result.data.message, "error")
                                                    } else {
                                                        this.props.controller.hardRefresh(() => {
                                                            const arrayBuffer = Uint8Array.from(result.data.label.data).buffer;

                                                            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                                                            const blobUrl = URL.createObjectURL(blob);
                                                            window.open(blobUrl, "_blank")
                                                        });

                                                        SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                                    }
                                                }).catch( () => {
                                                    this.props.setState({
                                                        dimmer: false
                                                    });
                                                    SnackBar("Nie udało się nadać paczki, sprawdź poprwaność danych lub skontaktuj się z administratorem", "error")
                                                })
                                            })
                                            .CheckForPersonalizedProducts()
                                            .Create()
                                        }>
                                            Nadaj paczkę
                                        </Button>
                                    </div>

                                    {orderData.delivery && orderData.delivery.pickupNumber &&
                                        <div style={{
                                            marginTop: 5,
                                            display: "flex",
                                            justifycontent: "center"
                                        }}>
                                            <Button secondary onClick={() => {
                                                const protocol = orderData.delivery.protocol ?? '';
                                                api().get(`/api/orders/delivery/getLabel/${orderData.delivery.pickupNumber}/gls?format=smallPdf&parlour=${DATA_OBJECT.selected_parlour}&protocol=${protocol}`).then(
                                                    (result) => {
                                                        if(result.data.error) {
                                                            return SnackBar(result.data.message, "error")
                                                        }
                                                        const arrayBuffer = Uint8Array.from(result.data.label.data).buffer;

                                                        const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                                                        const blobUrl = URL.createObjectURL(blob);
                                                        window.open(blobUrl, "_blank")
                                                    }
                                                )
                                            }}>
                                                Pobierz etykietę
                                            </Button>

                                            <Button icon={"trash"} onClick={() => {
                                                Confirm("Czy na pewno chcesz trwale usunąć ten list przewozowy?", null, () => {
                                                    this.props.setState({
                                                        dimmer: true
                                                    });

                                                    api().post("/api/orderService/deletePickup",
                                                        {
                                                            orderId: orderData.id,
                                                            pickupNumber: orderData.delivery.pickupNumber,
                                                            userId: DATA_OBJECT["user"]["login"]
                                                        });

                                                    setTimeout(() => {
                                                        this.props.controller.hardRefresh();
                                                    }, 250)
                                                })
                                            }}/>

                                            <Button icon={"edit"} onClick={() => {
                                                this.props.setState({
                                                    packNumberChange: {
                                                        orderId: orderData.id,
                                                        pickupNumber: orderData.delivery.pickupNumber,
                                                        orderServiceId: null,
                                                        provider: "gls",
                                                        userId: DATA_OBJECT["user"]["login"],
                                                    }
                                                });
                                            }}/>
                                        </div>
                                    }

                                    {orderData.delivery && orderData.delivery.multiDelivery &&
                                        <div style={{
                                            marginTop: 5
                                        }}>
                                            <PDFDownloadLink
                                                document={<MultiDeliveryPDF orders={orderData.delivery.multiDelivery}/>}
                                            >
                                                {({ blob, url, loading, error }) => {
                                                    return (
                                                        <Button
                                                            loading={loading}
                                                            disabled={loading}
                                                            secondary
                                                            onClick={() => {
                                                                window.open(url, '_blank')
                                                            }}
                                                        >
                                                            Etykieta zbiorcza
                                                        </Button>
                                                    )
                                                }}
                                            </PDFDownloadLink>
                                        </div>
                                    }
                                </div>
                            </div>
                        }

                        {(this.props.shippingMethods[orderData.shippingMethodId] && this.props.shippingMethods[orderData.shippingMethodId].provider && this.props.shippingMethods[orderData.shippingMethodId].provider.id.toLowerCase() === "dpd" && this.props.shippingMethods[orderData.shippingMethodId]?.marketplace) &&
                            <div className={"order-delivey-warpper"}>
                                <div style={{
                                    marginLeft: 10
                                }} className={"order-delivery-info-wrapper"}>
                                    {orderData.delivery && orderData.delivery.data && orderData.delivery.data.pickupFile &&
                                        <div style={{
                                            marginBottom: 10
                                        }}>
                                            <div className={"order-delivery-warpper-label-box"}>
                                                <div
                                                    className={"order-delivery-wrapper-label"}>{TranslationController.translate("Data nadania paczki")}:
                                                </div>
                                                <div
                                                    className={"order-delivery-warpper-value"}>{formatDate(orderData.delivery.createdTime).dateTimeSystem}</div>
                                            </div>

                                            <div className={"order-delivery-warpper-label-box"}>
                                                <div
                                                    className={"order-delivery-wrapper-label"}>{TranslationController.translate("Numer paczki")}:
                                                </div>
                                                <a href={`https://tracktrace.dpd.com.pl/parcelDetails?typ=1&p1=${orderData.delivery.data.packageNumber}`}
                                                   target={"_blank"}
                                                   className={"order-delivery-warpper-value"}>{orderData.delivery.data.packageNumber}</a>
                                            </div>

                                            {orderData.delivery.user &&
                                                <div className={"order-delivery-warpper-label-box"}>
                                                    <div
                                                        className={"order-delivery-wrapper-label"}>{TranslationController.translate("Użytkownik")}:
                                                    </div>
                                                    <div
                                                        className={"order-delivery-warpper-value"}>{orderData.delivery.user}</div>
                                                </div>
                                            }
                                        </div>
                                    }

                                    <div className={"flex"}>
                                        <Button secondary onClick={() =>
                                            new LabelCreationWrapper({
                                                provider: this.props,
                                                orderData: orderData
                                            })
                                                .RegisterCallbackAction(() => {
                                                    api()
                                                        .post("/api/orders/delivery/create",
                                                            {
                                                                orderId: orderData.id.toString(),
                                                                provider: 'baselinker',
                                                                user: DATA_OBJECT.user_login,
                                                            })
                                                        .then(result => {
                                                            this.props.setState({
                                                                dimmer: false
                                                            });

                                                            if (result.data.error) {
                                                                return SnackBar(result.data.message, "error")
                                                            } else {
                                                                this.props.controller.hardRefresh((orderData) => {
                                                                    const courierCode = orderData.allegroData.courierCode || "allegrokurier";
                                                                    controlPanelApi.get(`/baselinker/get-label/${courierCode}/${result.data.packageId}`)
                                                                        .then(data => {
                                                                            const arrayBuffer = Uint8Array.from(data.data.label.data).buffer;

                                                                            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                                                                            const blobUrl = URL.createObjectURL(blob);
                                                                            window.open(blobUrl, "_blank")});
                                                                });

                                                                SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                                            }
                                                        })
                                                })
                                                .CheckForPersonalizedProducts()
                                                .Create()
                                        }>
                                            Nadaj paczkę
                                        </Button>
                                    </div>


                                    {orderData.delivery && orderData.delivery.data && orderData.delivery.data.pickupFile &&
                                        <div style={{
                                            marginTop: 5,
                                            display: "flex",
                                            justifycontent: "center"
                                        }}>
                                            <Button
                                                onClick={() => {
                                                    const courierCode = orderData.allegroData.courierCode || 'allegrokurier';
                                                    controlPanelApi.get(`/baselinker/get-label/${courierCode}/${orderData.delivery.data.packageId}`)
                                                        .then(data => {
                                                            const arrayBuffer = Uint8Array.from(data.data.label.data).buffer;

                                                            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                                                            const blobUrl = URL.createObjectURL(blob);
                                                            window.open(blobUrl, "_blank")});
                                                }}
                                                primary
                                            >
                                                Pobierz etykietę
                                            </Button>
                                        </div>
                                    }
                                </div>
                            </div>
                        }

                        {(this.props.shippingMethods[orderData.shippingMethodId] && this.props.shippingMethods[orderData.shippingMethodId].provider && this.props.shippingMethods[orderData.shippingMethodId].provider.id === "dpd" && !this.props.shippingMethods[orderData.shippingMethodId]?.marketplace) &&
                            <div className={"order-delivey-warpper"}>
                                <div style={{
                                    marginLeft: 10
                                }} className={"order-delivery-info-wrapper"}>
                                    {orderData.delivery && orderData.delivery.data && orderData.delivery.data.pickupFile &&
                                        <div style={{
                                            marginBottom: 10
                                        }}>
                                            <div className={"order-delivery-warpper-label-box"}>
                                                <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Data nadania paczki")}:</div>
                                                <div className={"order-delivery-warpper-value"}>{formatDate(orderData.delivery.createdTime).dateTimeSystem}</div>
                                            </div>

                                            <div className={"order-delivery-warpper-label-box"}>
                                                <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Numer paczki")}:</div>
                                                <a href={`https://tracktrace.dpd.com.pl/parcelDetails?typ=1&p1=${orderData.delivery.data.pickupNumber}`} target={"_blank"} className={"order-delivery-warpper-value"}>{orderData.delivery.data.pickupNumber}</a>
                                            </div>

                                            {orderData.delivery.user &&
                                                <div className={"order-delivery-warpper-label-box"}>
                                                    <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Użytkownik")}:</div>
                                                    <div className={"order-delivery-warpper-value"}>{orderData.delivery.user}</div>
                                                </div>
                                            }
                                        </div>
                                    }

                                    <div className={"flex"}>
                                        <Button primary onClick={() =>
                                            new LabelCreationWrapper({
                                                provider: this.props,
                                                orderData: orderData
                                            })
                                                .RegisterCallbackAction(() => {
                                                    api()
                                                        .post("/api/orders/delivery/create",
                                                            {
                                                                orderId: orderData.id,
                                                                provider: "dpd",
                                                                user: DATA_OBJECT.user_login,
                                                            })
                                                        .then(result => {
                                                            this.props.setState({
                                                                dimmer: false
                                                            });

                                                            if (result.data.error) {
                                                                return SnackBar(result.data.message, "error")
                                                            } else {
                                                                this.props.controller.hardRefresh((orderData) => {
                                                                    window.open(`${STATIC_URL}${orderData.delivery.data.pickupFile}`)
                                                                });

                                                                SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                                            }
                                                        })
                                                })
                                                .CheckForPersonalizedProducts()
                                                .Create()
                                        }>
                                            Nadaj paczkę
                                        </Button>
                                    </div>

                                    {orderData.delivery && orderData.delivery.data && orderData.delivery.data.pickupFile &&
                                        <div style={{
                                            marginTop: 5,
                                            display: "flex",
                                            justifycontent: "center"
                                        }}>
                                            <Button secondary onClick={() => {
                                                window.open(`${STATIC_URL}${orderData.delivery.data.pickupFile}`)
                                            }}>
                                                Pobierz etykietę
                                            </Button>

                                            <Button icon={"trash"} onClick={() => {
                                                Confirm("Czy na pewno chcesz trwale usunąć ten list przewozowy?", null, () => {
                                                    this.props.setState({
                                                        dimmer: true
                                                    });

                                                    api().post("/api/orderService/deletePickup",
                                                        {
                                                            orderId: orderData.id,
                                                            pickupNumber: orderData.delivery.data.pickupNumber,
                                                            userId: DATA_OBJECT["user"]["login"]
                                                        });

                                                    setTimeout(() => {
                                                        this.props.controller.hardRefresh();
                                                    }, 250)
                                                })
                                            }}/>

                                            <Button icon={"edit"} onClick={() => {
                                                this.props.setState({
                                                    packNumberChange: {
                                                        orderId: orderData.id,
                                                        pickupNumber: orderData.delivery.data.pickupNumber,
                                                        orderServiceId: null,
                                                        provider: "dpd",
                                                        userId: DATA_OBJECT["user"]["login"],
                                                    }
                                                });
                                            }}/>
                                        </div>
                                    }

                                    {orderData.delivery && orderData.delivery.multiDelivery &&
                                        <div style={{
                                            marginTop: 5
                                        }}>
                                            <PDFDownloadLink
                                                document={<MultiDeliveryPDF orders={orderData.delivery.multiDelivery}/>}
                                            >
                                                {({ blob, url, loading, error }) => {
                                                    return (
                                                        <Button
                                                            loading={loading}
                                                            disabled={loading}
                                                            secondary
                                                            onClick={() => window.open(url, '_blank')}
                                                        >
                                                            Etykieta zbiorcza
                                                        </Button>
                                                    )
                                                }}
                                            </PDFDownloadLink>
                                        </div>
                                    }
                                </div>
                            </div>
                        }

                        {(this.props.shippingMethods[orderData.shippingMethodId] && this.props.shippingMethods[orderData.shippingMethodId].provider && this.props.shippingMethods[orderData.shippingMethodId].provider.id === "dhl" && this.props.shippingMethods[orderData.shippingMethodId]?.marketplace) &&
                            <div className={"order-delivey-warpper"}>
                                <div style={{
                                    marginLeft: 10
                                }} className={"order-delivery-info-wrapper"}>
                                    {orderData.delivery && orderData.delivery.data &&
                                        <div style={{
                                            marginBottom: 10
                                        }}>
                                            <div className={"order-delivery-warpper-label-box"}>
                                            <div className={"order-delivery-wrapper-label"}>Data nadania paczki:</div>
                                            <div className={"order-delivery-warpper-value"}>{formatDate(orderData.delivery.createdTime).dateTimeSystem}</div>
                                            </div>

                                            <div className={"order-delivery-warpper-label-box"}>
                                                <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Numer paczki")}:</div>
                                                <div className={"order-delivery-warpper-value"}>{orderData.delivery.data.packageNumber}</div>
                                            </div>

                                            {orderData.delivery.user &&
                                                <div className={"order-delivery-warpper-label-box"}>
                                                    <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Użytkownik")}:</div>
                                                    <div className={"order-delivery-warpper-value"}>{orderData.delivery.user}</div>
                                                </div>
                                            }
                                        </div>
                                    }

                                    <div className={"flex"}>
                                        <Button secondary onClick={() =>
                                            new LabelCreationWrapper({
                                                provider: this.props,
                                                orderData: orderData
                                            })
                                                .RegisterCallbackAction(() => {
                                                    api()
                                                        .post("/api/orders/delivery/create",
                                                            {
                                                                orderId: orderData.id,
                                                                provider: "baselinker",
                                                                user: DATA_OBJECT.user_login,
                                                            })
                                                        .then(result => {
                                                            if (result.data.error) {
                                                                this.props.setState({
                                                                    dimmer: false
                                                                });

                                                                return SnackBar(result.data.message, "error")
                                                            } else {
                                                                console.log(result, 'result')
                                                                console.log(result.data, 'result.data')
                                                                setTimeout(() => {
                                                                    this.props.controller.hardRefresh((orderData) => {
                                                                        const courierCode = orderData.allegroData.courierCode || "allegrokurier";
                                                                        controlPanelApi.get(`/baselinker/get-label/${courierCode}/${result.data.packageId}`)
                                                                            .then(data => {
                                                                                const arrayBuffer = Uint8Array.from(data.data.label.data).buffer;

                                                                                const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                                                                                const blobUrl = URL.createObjectURL(blob);
                                                                                window.open(blobUrl, "_blank")});
                                                                    });

                                                                    SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                                                }, 2000)
                                                            }
                                                        })
                                                })
                                                .CheckForPersonalizedProducts()
                                                .Create()
                                        }>
                                            Nadaj paczkę
                                        </Button>
                                    </div>

                                    {orderData.delivery && orderData.delivery.data && orderData.delivery.status === "created" &&
                                        <div style={{
                                            marginTop: 5
                                        }}>
                                            <Button
                                                onClick={() => {
                                                    const courierCode = orderData.allegroData.courierCode || "allegrokurier";
                                                    controlPanelApi.get(`/baselinker/get-label/${courierCode}/${orderData.delivery.data.packageId}`)
                                                        .then(data => {
                                                            const arrayBuffer = Uint8Array.from(data.data.label.data).buffer;

                                                            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                                                            const blobUrl = URL.createObjectURL(blob);
                                                            window.open(blobUrl, "_blank")});
                                                }}
                                                primary
                                            >
                                                Pobierz etykietę
                                            </Button>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        {(this.props.shippingMethods[orderData.shippingMethodId] && this.props.shippingMethods[orderData.shippingMethodId].provider && this.props.shippingMethods[orderData.shippingMethodId].provider.id === "dhl" && !this.props.shippingMethods[orderData.shippingMethodId]?.marketplace) &&
                            <div className={"order-delivey-warpper"}>
                                <div style={{
                                    marginLeft: 10
                                }} className={"order-delivery-info-wrapper"}>
                                    {orderData.delivery && orderData.delivery.data && orderData.delivery.data.CreationState && orderData.delivery.data.CreationState[0] && orderData.delivery.data.CreationState[0].shipmentNumber &&
                                        <div style={{
                                            marginBottom: 10
                                        }}>
                                            {/*<div className={"order-delivery-warpper-label-box"}>*/}
                                            {/*<div className={"order-delivery-wrapper-label"}>Data nadania paczki:</div>*/}
                                            {/*<div className={"order-delivery-warpper-value"}>{formatDate(orderData.delivery.createdTime).dateTimeSystem}</div>*/}
                                            {/*</div>*/}

                                            <div className={"order-delivery-warpper-label-box"}>
                                                <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Numer paczki")}:</div>
                                                <div className={"order-delivery-warpper-value"}>{orderData.delivery.data.CreationState[0].shipmentNumber}</div>
                                            </div>

                                            <div className={"order-delivery-warpper-label-box"}>
                                                <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Numer paczki zwrotnej")}:</div>
                                                <div className={"order-delivery-warpper-value"}>{orderData.delivery.data.CreationState[0].returnShipmentNumber}</div>
                                            </div>

                                            {orderData.delivery.user &&
                                                <div className={"order-delivery-warpper-label-box"}>
                                                    <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Użytkownik")}:</div>
                                                    <div className={"order-delivery-warpper-value"}>{orderData.delivery.user}</div>
                                                </div>
                                            }
                                        </div>
                                    }

                                    <div className={"flex"}>
                                        <Button primary onClick={() =>
                                            new LabelCreationWrapper({
                                                provider: this.props,
                                                orderData: orderData
                                            })
                                                .RegisterCallbackAction(() => {
                                                    api()
                                                        .post("/api/orders/delivery/create",
                                                            {
                                                                orderId: orderData.id,
                                                                provider: "dhl",
                                                                user: DATA_OBJECT.user_login,
                                                            })
                                                        .then(result => {
                                                            this.props.setState({
                                                                dimmer: false
                                                            });

                                                            if (result.data.error) {
                                                                if (result.data.data.statusMessage) {
                                                                    result.data.data.statusMessage.forEach(e => {
                                                                        SnackBar(e, "error")
                                                                    })
                                                                }

                                                                return false
                                                            } else {
                                                                this.props.controller.hardRefresh();

                                                                SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                                            }
                                                        })
                                                })
                                                .CheckForPersonalizedProducts()
                                                .Create()
                                        }>
                                            Nadaj paczkę
                                        </Button>
                                    </div>

                                    {orderData.delivery && orderData.delivery.data && orderData.delivery.data.CreationState && orderData.delivery.data.CreationState[0] && orderData.delivery.data.CreationState[0].LabelData && orderData.delivery.data.CreationState[0] && orderData.delivery.data.CreationState[0].LabelData.labelUrl &&
                                        <div style={{
                                            marginTop: 5,
                                            display: "flex",
                                            justifycontent: "center"
                                        }}>
                                            <Button secondary onClick={() => {
                                                window.open(orderData.delivery.data.CreationState[0].LabelData.labelUrl)
                                            }}>
                                                Pobierz etykietę
                                            </Button>

                                            <Button icon={"trash"} onClick={() => {
                                                Confirm("Czy na pewno chcesz trwale usunąć ten list przewozowy?", null, () => {
                                                    this.props.setState({
                                                        dimmer: true
                                                    });

                                                    api().post("/api/orderService/deletePickup",
                                                        {
                                                            orderId: orderData.id,
                                                            pickupNumber: orderData.delivery.data.CreationState[0].shipmentNumber,
                                                            userId: DATA_OBJECT["user"]["login"]
                                                        });

                                                    setTimeout(() => {
                                                        this.props.controller.hardRefresh();
                                                    }, 250)
                                                })
                                            }}/>

                                            <Button icon={"edit"} onClick={() => {
                                                this.props.setState({
                                                    packNumberChange: {
                                                        orderId: orderData.id,
                                                        pickupNumber: orderData.delivery.data.CreationState[0].shipmentNumber,
                                                        orderServiceId: null,
                                                        userId: DATA_OBJECT["user"]["login"],
                                                    }
                                                });
                                            }}/>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }
            </PaperComponent>
        )
    }
}