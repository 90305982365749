import React from 'react'
import {Loader, Message} from "semantic-ui-react";
import ActionPanel from "../../components/ActionPanel";
import Container from "../../components/Container";
import Breadcrumb from "../../components/Breadcrumb";
import Paper from '@material-ui/core/Paper';
import {api} from "../../lib/axios";
import {Modal} from "semantic-ui-react/dist/commonjs/modules/Modal";
import Check from '@material-ui/icons/Check';
import OrderPackingPDFDocument from "./orderPackingPDF";
import {TranslationController} from "../../controller/menager";
import WarehouseOrderPackingPDFDocument from "./orderPackingPDF";

export default class WarehouseOrderService extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orderServiceData: null,
            activeStep: 1,
            steps: [
                "Sprawdź zamówienie", "Skompletuj zamówienie", "---"
            ],
            parlour: null,
            orderId: null
        }
    }

    componentDidMount() {
        this.init();

        if (this.props.location.pathname.split("&")[2] && this.props.location.pathname.split("&")[2] === "pdf") {
            this.setState({
                pdfOnly: true
            })
        }
    }

    init() {
        const rowId = this.props.location.pathname.split("/")[this.props.location.pathname.split("/").length - 1].split("&")[0];
        this.state.rowId = rowId;

        api().post("/api/orderService", {
            id: rowId,
        })
            .then(r => {
                if (r.data.data) {
                    this.setState({
                        orderServiceData: r.data.data[0],
                        parlour: r.data.data[0].parlour,
                        orderId: r.data.data[0].orderId,
                        previouslyScanned: false
                    })
                }
            })
    }

    QontoStepIcon(props) {
        const { active, completed } = props;

        return (
            <div>
                {completed ? <Check style={{color: "#0078d7"}} /> : <div style={{width: 10, height: 10, background: active ? "#0078d7" : "#eaeaf0", borderRadius: "100%", position: "relative", top: 7}} />}
            </div>
        );
    }

    nextStep() {
        if (this.state.activeStep < 2) {
            this.setState({
                activeStep: this.state.activeStep + 1
            })
        }
    }

    prevStep() {
        if (this.state.activeStep > 0) {
            this.setState({
                activeStep: this.state.activeStep - 1
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <ActionPanel
                    actions={[
                    ]}
                />

                <Container>
                    <Breadcrumb
                        crumbs={[
                            {
                                label: `${TranslationController.translate("Obsługa zamówienia")}  ${this.state.parlour ? (`${this.state.parlour}/${this.state.orderId}`) : ""}`
                            }
                        ]}
                    />

                    <div style={{height: 10}}></div>

                    <Paper>
                        <Message
                            icon='inbox'
                            header={TranslationController.translate("Obsługa zamówienia")}
                        />

                        {this.state.orderServiceData ?
                            <React.Fragment>
                                <div className={"order-service-wrapper"}>
                                    <WarehouseOrderPackingPDFDocument
                                        rowId={this.state.rowId}
                                        parlour={this.state.parlour}
                                        orderId={this.state.orderId}
                                        next={() => this.nextStep()}
                                        data={this.state.orderServiceData}
                                        previouslyScanned={this.state.previouslyScanned}
                                        reset={() => this.setState({
                                            orderServiceData: null
                                        })}
                                        pdfOnly={this.state.pdfOnly}
                                        recallData={() => this.init()}
                                    />
                                </div>
                            </React.Fragment>
                            : <div><Loader active></Loader></div>}
                    </Paper>
                </Container>
            </React.Fragment>
        );
    }
}
